export class LoginV2Request {
	UserName: string;
	Password: string;
	UserType: enuUserType;
}

export enum enuUserType {
	MiyaKullanici = 0,
	Token = 1,
	PanoramaKullanici = 2,
	Plaka = 3,
	MiyaToken = 4
}
