export class PlakaRandevuListesiRequest {
	txtPlaka: string;
	filter: Filter;
	paging: Paging;
}

export class Paging {
	sayfa: number;
}

export class Filter {
	lngBayiLokasyonKod: number;
	lngDistKod: number;
	lngRandevuDurum: number;
	lngRandevuTipi: number;
	txtAracKullanicisi: string;
	txtPlaka: string;
	txtEmail: string;
	txtTelefon: string;
	trhRandevuTarihi: string;
}
