import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Toaster } from 'ngx-toast-notifications';
import { AuthService } from 'app/core/auth/_services/auth.service';
import { ConfigService } from 'app/core/config.service';

@Injectable()
export class InterceptService implements HttpInterceptor {
	constructor(private toaster: Toaster, private authService: AuthService, private configService: ConfigService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!request.url.startsWith('http') && request.url !== './assets/config.json') {
			let reqUrl = request.urlWithParams;
			if (reqUrl[0] === '/') reqUrl = reqUrl.substring(1);
			const httpRequest = new HttpRequest(<any>request.method, this.configService.config.apiUrl + reqUrl, request.body);
			request = Object.assign(request, httpRequest);
		}
		const referer = window.location.href; // Bu, anlık sayfanın URL'sini alır
		const yeniIstek = request.clone({
			setHeaders: {
				Authorization: `bearer ${localStorage.getItem('accessToken')}`,
				Xreferer: referer
			}
		});

		return next.handle(yeniIstek).pipe(
			catchError((response: HttpErrorResponse) => {
				if (!response.status) {
					// this.showToast( "Config json dosyasına girmiş olduğunuz api url yanlış","Api Url Yanlış", "danger");
					return throwError(response);
				}
				if (response.status === 401) {
					this.authService.logout();
				}
				if (response.status !== 401) {
					const networkLogId = response.headers.get('NetworkLogId');
					const networkLogMesaj = networkLogId ? `Mesaj Kodu: ${networkLogId}` : '';
					const err = response.error;

					let hataMesaji = err.message ? err.message : err.error?.stack;

					let jsonErrorProp;
					if (err.errors) {
						jsonErrorProp = Object.keys(err.errors)[0];
					} else {
						jsonErrorProp = Object.keys(err)[1];
					}

					if (jsonErrorProp) {
						hataMesaji = err[jsonErrorProp];
					}

					if (err.errors) {
						if (jsonErrorProp) {
							hataMesaji = err.errors[jsonErrorProp][0];
						}
					}

					this.toaster.open({ text: networkLogMesaj, caption: hataMesaji, type: 'danger' });
					throw new Error(hataMesaji);
				} else {
					return throwError(response);
				}
			})
		);
	}
}
