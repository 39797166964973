import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'brisa-header',
	templateUrl: './brisa-header.component.html',
	styleUrls: ['brisa-header.component.scss']
})
export class BrisaheaderComponent implements OnInit {
	@Input() distadi: string;
	@Input() logintipi: string;
	slogan1: string;
	slogan2: string;
	@Input() plakaGoster = false;

	constructor(public router: Router) {}

	ngOnInit(): void {
		if (localStorage.getItem('loginType') != 'filo' && !this.distadi) {
			this.distadi = localStorage.getItem('unvan');
		}
		this.logintipi = localStorage.getItem('loginType');
		this.slogan1 = localStorage.getItem('sloganAdi1');
		this.slogan2 = localStorage.getItem('sloganAdi2');
	}
}
