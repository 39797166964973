export class MenuConfig {
	public defaults = {
		header: {
			self: {},
			items: []
		},
		aside: {
			self: {},
			items: [
				// { section: 'Randevu' },
				{
					title: 'Randevu',
					root: true,
					icon: 'flaticon2-browser-2',
					hidden:
						localStorage.getItem('loginType') === 'filo' ||
						localStorage.getItem('loginType') === 'plaka' ||
						((localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer') &&
							localStorage.getItem('bayiyetkili') === '1')
							? false
							: true,
					submenu: [
						// * PLAKA MENÜLERİ
						{
							title: 'Randevu Alma',
							root: true,
							icon: 'flaticon2-search-1',
							page: '/lastik-arama',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'plaka' ? false : true
						},
						// * FİLO MENÜLERİ
						{
							title: 'Randevu Girişi',
							root: true,
							icon: 'flaticon-edit-1',
							page: '/filo-randevu-girisi',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						{
							title: 'Takvim',
							root: true,
							icon: 'flaticon-event-calendar-symbol',
							page: '/filo-takvimi',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						{
							title: 'Günlük Randevu Listesi',
							root: true,
							icon: 'fas fa-clipboard-list',
							page: '/filo-gunluk-randevu',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						{
							title: 'Excel Yükleme',
							root: true,
							icon: 'fas fa-file-excel',
							page: '/filo-excel-upload',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						// * BAYİ MENÜLERİ
						{
							title: 'Takvim',
							root: true,
							icon: 'flaticon-event-calendar-symbol',
							page: '/bayi-takvimi',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						},
						{
							title: 'Günlük Randevu Listesi',
							root: true,
							icon: 'fas fa-clipboard-list',
							page: '/hizmet-gunluk-randevu',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						}
					]
				},
				{
					title: 'Randevu Parametreleri',
					root: true,
					icon: 'fas fa-cogs',
					hidden:
						(localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer') &&
						localStorage.getItem('bayiyetkili') === '1'
							? false
							: true,
					submenu: [
						{
							title: 'Gün ve Saat Kapatma',
							root: true,
							icon: 'fas fa-window-close',
							page: '/gun-ve-saat-kapatma',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						},
						{
							title: 'Hizmet Noktası Tanımlama',
							root: true,
							icon: 'flaticon2-add',
							page: '/hizmet-noktasi-tanimlama',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						},
						{
							title: 'Hizmet Noktası Parametreleri',
							root: true,
							icon: 'fas fa-cog',
							page: '/hizmet-noktasi-ekleme',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						}
						// {
						// 	title: 'Lokasyon Depo Eşleme',
						// 	root: true,
						// 	icon: 'flaticon-home-2',
						// 	page: '/lokasyon-depo-esleme',
						// 	translate: '',
						// 	bullet: 'dot',
						// 	hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
						// }
					]
				},
				// {
				// 	section: 'Lastik Oteli',
				// 	hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
				// },
				{
					title: 'Lastik Oteli',
					root: true,
					icon: 'flaticon2-browser-2',
					hidden:
						(localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer') &&
						localStorage.getItem('bayiyetkili') === '1'
							? false
							: true,
					submenu: [
						{
							title: 'Yeni Saklama',
							root: true,
							icon: 'flaticon2-add',
							page: '/yeni-saklama',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						},
						{
							title: 'Saklama Listesi',
							root: true,
							icon: 'fas fa-warehouse',
							page: '/saklama-listesi',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						},
						{
							title: 'Raf Tanımlama',
							root: true,
							icon: 'fas fa-pallet',
							page: '/raf-tanimlama',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						},
						{
							title: 'İş Emri Oluştur',
							root: true,
							icon: 'fas fa-calendar-alt',
							page: '/is-emri-olustur',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						},
						{
							title: 'Excel Yükle',
							root: true,
							icon: 'fas fa-file-excel',
							page: '/excel-yukle2',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						},
						{
							title: 'Terminal Kullanıcı Tanımı',
							root: true,
							icon: 'fas fa-user-plus',
							page: '/terminal-kullanici-tanimi',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						}
					]
				},

				// {
				// 	section: 'Performans Lastik Arama',
				// 	hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
				// },
				{
					title: 'Performans Lastik',
					root: true,
					icon: 'flaticon2-browser-2',
					hidden:
						localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
							? false
							: true,
					submenu: [
						{
							title: 'Performans Lastik Arama',
							root: true,
							icon: 'flaticon2-search',
							page: '/performans-lastik-arama',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						},
						{
							title: 'Performans Lastik Parametreleri',
							root: true,
							icon: 'flaticon2-add',
							page: '/performans-lastik-parametreleri',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' && localStorage.getItem('txtPerformansAnlasma') === 'Evet'
									? false
									: true
						}
					]
				},
				// {
				// 	section: 'OFK',
				// 	hidden: localStorage.getItem('loginType') === 'filo' ? false : true
				// },
				{
					title: 'OFK',
					root: true,
					icon: 'flaticon2-browser-2',
					hidden:
						localStorage.getItem('loginType') === 'filo' ||
						((localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer') &&
							localStorage.getItem('bayiyetkili') === '1')
							? false
							: true,
					submenu: [
						{
							title: 'Sipariş Girişi',
							root: true,
							icon: 'fas fa-file-medical',
							page: '/siparis-girisi',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						{
							title: 'Filo Sözleşmeli Bayi Seçimi',
							root: true,
							icon: 'fas fa-cog',
							page: '/filo-sozlesmeli-secim',
							translate: '',
							bullet: 'dot'
							// hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						{
							title: 'Siparişlerim',
							root: true,
							icon: 'fas fa-clipboard-list',
							page: '/siparislerim',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						{
							title: 'Taslaklarım',
							root: true,
							icon: 'fas fa-clipboard-list',
							page: '/taslaklarim',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						{
							title: 'Excel Toplu Sipariş Girişi',
							root: true,
							icon: 'fas fa-file-excel',
							page: '/excel-yukle',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						{
							title: 'Excel Siparişlerim',
							root: true,
							icon: 'fas fa-table',
							page: '/excel-siparislerim',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						{
							title: 'Kullanıcı Ayarları',
							root: true,
							icon: 'fas fa-table',
							page: '/kullanici-ayarlari',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'filo' ? false : true
						},
						{
							title: 'Bayi Onay İşlemleri',
							root: true,
							icon: 'fas fa-table',
							page: '/bayi-onay-islemleri',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
						},
						{
							title: 'Bayi Lokasyon Girişi',
							root: true,
							icon: 'fas fa-table',
							page: '/bayi-lokasyon-girisi',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
						},
						{
							title: 'Bayi Sözleşme Girişi',
							root: true,
							icon: 'fas fa-table',
							page: '/bayi-sozlesme-girisi',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
						},
						{
							title: 'Bayi Yönetim İşlemleri',
							root: true,
							icon: 'fas fa-table',
							page: '/bayi-yonetim-islemleri',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
						}
					]
				},
				{
					title: 'Filo Hizmet',
					root: true,
					icon: 'flaticon2-browser-2',
					hidden:
						(localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer') &&
						localStorage.getItem('bayiyetkili') === '1'
							? false
							: true,
					submenu: [
						{
							title: 'Filo Hizmet Formu',
							root: true,
							icon: 'fas fa-file-medical',
							page: '/filo-hizmet-formu-tanimlama',
							translate: '',
							bullet: 'dot',
							hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
						},

						{
							title: 'Filo Hizmet İşlemleri',
							root: true,
							icon: 'fas fa-file-medical',
							page: '/filo-hizmet-islemleri',
							translate: '',
							bullet: 'dot',
							hidden:
								localStorage.getItem('loginType') === 'bayi' || localStorage.getItem('loginType') === 'diğer'
									? false
									: true
						},
						{
							title: 'Filo Hizmet Tanımı',
							root: true,
							icon: 'flaticon2-browser-2',
							hidden: localStorage.getItem('loginType') === 'bayi' ? false : true,
							submenu: [
								{
									title: 'Hizmet Kalemleri Tanımlama',
									root: true,
									icon: 'fas fa-file-medical',
									page: '/hizmet-kalemleri-tanimlama',
									translate: '',
									bullet: 'dot',
									hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
								},
								{
									title: 'Ürün Üst Grup Tanımlama',
									root: true,
									icon: 'fas fa-file-medical',
									page: '/urun-ust-grup-tanimlama',
									translate: '',
									bullet: 'dot',
									hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
								},
								{
									title: 'Ürün Alt Grup Tanımlama',
									root: true,
									icon: 'fas fa-file-medical',
									page: '/urun-alt-grup-tanimlama',
									translate: '',
									bullet: 'dot',
									hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
								},

								{
									title: 'Ürün Üst ve Ürün Alt Grup Eşleme',
									root: true,
									icon: 'fas fa-file-medical',
									page: '/urun-esleme',
									translate: '',
									bullet: 'dot',
									hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
								},
								{
									title: 'Sms ve Email Hak Tanımı',
									root: true,
									icon: 'fas fa-file-medical',
									page: '/email-sms-hakki',
									translate: '',
									bullet: 'dot',
									hidden: localStorage.getItem('loginType') === 'bayi' ? false : true
								}
							]
						}
					]
				}
			]
		}
	};

	public get configs(): any {
		return this.defaults;
	}
}
