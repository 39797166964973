import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { KullanıcıyaBagliFiloListesiResponese } from 'app/core/ofkas/models/response/kullaniciayarlari/kulanicibaglifilolistesi.response';
import { KullaniciAyarlariService } from 'app/core/ofkas/services/kullanici-ayarlari.service';

import swal from 'sweetalert2';
declare let $: any;
@Component({
	selector: 'kt-filo-selectbox',
	templateUrl: './filo-selectbox.component.html',
	styles: ['::ng-deep.filtre>.mat-form-field-wrapper{padding-bottom: 0px !important;margin-top:12px !important}']
})
export class FiloSelectComponent implements OnInit {
	public logintype: number;
	public selectaktif = false;
	public filoListesi: KullanıcıyaBagliFiloListesiResponese[] = [];
	public filovalue = '';
	@Output() public secim: EventEmitter<string> = new EventEmitter();

	constructor(private router: Router, private filoKullaniciServices: KullaniciAyarlariService) {
		filoKullaniciServices.kullaniciyaBagliFiloListesi$.subscribe(data => {
			if (!data) return;
			this.filoListesi = data;
			if (data.length === 1) {
				this.filovalue = data[0].Key;
				this.filoSecildi();
			} else {
				if (!localStorage.getItem('filokullanicikodu') && localStorage.getItem('loginType') === 'filo') {
					swal.fire('Uyarı', 'Lütfen filo seçiniz!', 'warning');
				}
			}
		});
	}

	ngOnInit(): void {
		this.logintype = localStorage.getItem('loginType') === 'merkez' ? 1 : 0;

		this.selectaktif = localStorage.getItem('loginType') === 'filo';

		if (this.selectaktif) {
			this.filoListeGetir();
		}

		if (localStorage.getItem('filokullanicikodu')) {
			this.filovalue = localStorage.getItem('filokullanicikodu');
		}
	}

	filoListeGetir(): void {
		this.filoKullaniciServices.getKullaniciyaBagliFiloListesi();
	}

	filoSecildi(): void {
		localStorage.setItem('filokullanicikodu', this.filovalue);
		this.secim.emit(this.filovalue);
		$('#filosecildi').click();
		if (this.router.url === '/siparis-girisi') {
			$('#loadersecildi').click();
		}
	}
}
