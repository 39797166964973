<mat-dialog-title>
	<h4 style="display: flex">
		Lastik Oteli Hizmet Alabileceğiniz BriSA Bayi Listesi
		<button
			mat-icon-button
			aria-label="close dialog"
			mat-dialog-close
			style="margin-left: auto; background-color: #000; color: #fff"
		>
			<mat-icon>close</mat-icon>
		</button>
	</h4>

	<hr />
	<form [formGroup]="lokasyonForm">
		<div class="row">
			<div class="col-sm-12 col-md-12">
				<div class="col-sm-4 col-md-4 col-xl-4 d-flex">
					<mat-icon class="mat-icon-custom">arrow_forward</mat-icon>
					<mat-form-field class="form-item" style="flex: 1" appearance="outline">
						<mat-label>İl</mat-label>
						<mat-select (selectionChange)="ilceDegistir($event.value)" formControlName="lngIlKod">
							<mat-option>
								<ngx-mat-select-search
									[noEntriesFoundLabel]="'Bulunamadı...'"
									[placeholderLabel]="'Arama'"
									formControlName="ilceFilter"
								>
								</ngx-mat-select-search>
							</mat-option>
							<mat-option
								*ngFor="let il of ilListesi | ilSearch : lokasyonForm.get('ilceFilter').value"
								[value]="il.lngIlKod"
								>{{ il.txtIlAdi }}</mat-option
							>
						</mat-select>
						<mat-error style="font-size: 0.8rem" *ngIf="isLokasyonControlHasError('lngIlKod', 'required')">
							Bu alan boş bırakılamaz
						</mat-error>
					</mat-form-field>
				</div>
				<mat-form-field class="col-sm-4 col-md-4 col-xl-4 form-item" appearance="outline">
					<mat-label>İlçe</mat-label>
					<mat-select formControlName="lngIlceKod">
						<mat-option>
							<ngx-mat-select-search
								[noEntriesFoundLabel]="'Bulunamadı...'"
								[placeholderLabel]="'Arama'"
								formControlName="ilceFilter"
							>
							</ngx-mat-select-search>
						</mat-option>
						<mat-option
							*ngFor="let ilce of ilceListesi | ilceSearch : lokasyonForm.get('ilceFilter').value"
							[value]="ilce.lngIlceKod"
							>{{ ilce.txtIlceAdi }}</mat-option
						>
					</mat-select>
				</mat-form-field>
				<div class="col-sm-4 col-md-4 col-xl-4">
					<button
						#matButton
						mat-raised-button
						cdkFocusInitial
						color="primary"
						*ngIf="duzenleniyor == false"
						(click)="ara()"
					>
						Ara
					</button>
				</div>
			</div>
		</div>
	</form>
	<hr />
</mat-dialog-title>
<mat-dialog-content style="min-height: 40vh">
	<table
		mat-table
		#table1
		matSort
		class="w-100 panel mat-table-custom"
		style="white-space: nowrap"
		[dataSource]="
			oneriBayiListesi
				| hoblTxtIlcePipe : filteredColumn.ilceFilter
				| hoblTxtLokasyonAdPipe : filteredColumn.lokasyonIsimFilter
				| hoblTxtBayiAdPipe : filteredColumn.bayiAdFilter
		"
	>
		<ng-container matColumnDef="konum">
			<th mat-header-cell *matHeaderCellDef>Konum Bilgisi</th>
			<td mat-cell *matCellDef="let row">
				<a
					target="_blank"
					mat-button
					class="whatsapp-icon"
					href="https://www.google.com/maps?q={{ row.dblXKoordinat }},{{ row.dblYKoordinat }}"
				>
					<img src="../../assets/icons/map.png" />
				</a>
			</td>
		</ng-container>
		<ng-container matColumnDef="yckFilter">
			<th mat-header-cell *matHeaderCellDef style="max-width: 80px"></th>
		</ng-container>
		<ng-container matColumnDef="il">
			<th mat-header-cell *matHeaderCellDef style="min-width: 85px">İl</th>
			<td mat-cell *matCellDef="let row">{{ row.txtIlAd }}</td>
		</ng-container>

		<ng-container matColumnDef="ilFilter">
			<th mat-header-cell *matHeaderCellDef style="max-width: 80px"></th>
		</ng-container>

		<ng-container matColumnDef="ilce">
			<th mat-header-cell *matHeaderCellDef>İlçe</th>
			<td mat-cell *matCellDef="let row">{{ row.txtIlceAd }}</td>
		</ng-container>
		<ng-container matColumnDef="ilceF">
			<th mat-header-cell *matHeaderCellDef>
				<mat-form-field class="w-100" appearance="outline" class="filterText">
					<mat-label>Filtrele</mat-label>
					<input
						matInput
						type="text"
						[(ngModel)]="filteredColumn.ilceFilter"
						name="ilceFilter"
						placeholder="Filtrele"
					/>
				</mat-form-field>
			</th>
		</ng-container>

		<ng-container matColumnDef="bayiIsim">
			<th mat-header-cell *matHeaderCellDef>Bayi İsmi</th>
			<td mat-cell *matCellDef="let row">{{ row.txtBayiAd }}</td>
		</ng-container>
		<ng-container matColumnDef="bayiAdF">
			<th mat-header-cell *matHeaderCellDef>
				<mat-form-field class="w-100" appearance="outline" class="filterText">
					<mat-label>Filtrele</mat-label>
					<input matInput type="text" [(ngModel)]="filteredColumn.bayiAdFilter" placeholder="Filtrele" />
				</mat-form-field>
			</th>
		</ng-container>

		<ng-container matColumnDef="lokasyonIsim">
			<th mat-header-cell *matHeaderCellDef>Lokasyon İsmi</th>
			<td mat-cell *matCellDef="let row">{{ row.txtLokasyonAd }}</td>
		</ng-container>

		<ng-container matColumnDef="lokasyonIsimF">
			<th mat-header-cell *matHeaderCellDef>
				<mat-form-field class="w-100" appearance="outline" class="filterText">
					<mat-label>Filtrele</mat-label>
					<input matInput type="text" [(ngModel)]="filteredColumn.lokasyonIsimFilter" placeholder="Filtrele" />
				</mat-form-field>
			</th>
		</ng-container>
		<ng-container matColumnDef="yetkiliMailAdresleri">
			<th mat-header-cell *matHeaderCellDef>Yetkili Mail Adresleri</th>
			<td mat-cell *matCellDef="let row">
				<ng-container *ngFor="let param of row.iletisimParametreler">
					<a
						*ngIf="param.bytTur === 1"
						href="mailto:{{ param.txtDeger }}"
						mat-button
						style="display: flex; flex-direction: column; text-align: left"
						matTooltip="{{ param.txtDeger }}"
					>
						{{ param.txtDeger }}
					</a>
				</ng-container>
			</td>
		</ng-container>
		<ng-container matColumnDef="ymaFilter">
			<th mat-header-cell *matHeaderCellDef style="max-width: 80px"></th>
		</ng-container>

		<ng-container matColumnDef="yetkiliCepTelAdresleri">
			<th mat-header-cell *matHeaderCellDef>Yetkili Cep Tel No</th>
			<td mat-cell *matCellDef="let row">
				<ng-container *ngFor="let param of row.iletisimParametreler">
					<div *ngIf="param.bytTur === 0 && (param.txtDeger.startsWith('05') || param.txtDeger.startsWith('5'))">
						<a
							target="_blank"
							mat-button
							class="whatsapp-icon"
							matTooltip="{{ param.txtDeger }}"
							href="https://api.whatsapp.com/send?phone=9{{ param.txtDeger }}"
						>
							<img src="../../assets/icons/whatsapp.png" />
						</a>
						<a href="tel:+{{ param.txtDeger }}">{{ param.txtDeger }}</a>
					</div>
				</ng-container>
			</td>
		</ng-container>
		<ng-container matColumnDef="yctFilter">
			<th mat-header-cell *matHeaderCellDef style="max-width: 80px"></th>
		</ng-container>

		<ng-container matColumnDef="adres">
			<th mat-header-cell *matHeaderCellDef>Adres</th>
			<td mat-cell *matCellDef="let row">{{ row.txtAdres }}</td>
		</ng-container>

		<ng-container matColumnDef="ycaFilter">
			<th mat-header-cell *matHeaderCellDef style="max-width: 80px"></th>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-header-row *matHeaderRowDef="displayedFiltreColumns; sticky: true"></tr>

		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</mat-dialog-content>
