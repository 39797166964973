// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { LoaderService } from '../utils/loader.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterCeptService implements HttpInterceptor {
	requestCounter = 0;

	constructor(public loaderService: LoaderService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const urlParts = req.url.split('/');
		if (urlParts[urlParts.length - 1] != 'MusteriKrediLimitleriGetir') {
			this.requestCounter++;
			// console.log(
			// 	`%c + ${this.requestCounter}  %c (${req.url})`,
			// 	'background: #444; color: #bada55',
			// 	'background: #444; color: #995555'
			// );
			Promise.resolve(null).then(() => this.loaderService.show());
		}

		return next.handle(req).pipe(
			finalize(() => {
				const urlParts = req.url.split('/');
				if (urlParts[urlParts.length - 1] != 'MusteriKrediLimitleriGetir') {
					this.requestCounter--;
					// console.log(
					// 	`%c - ${this.requestCounter}  %c (${req.url})`,
					// 	'background: #444; color: #ff5555',
					// 	'background: #444; color: #995555'
					// );
				}

				if (this.requestCounter <= 0) {
					this.loaderService.hide();
				}
			})
		);
	}
}
