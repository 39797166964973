import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuAsideService } from 'app/core/_base/layout';
import { MenuConfigService } from 'app/core/_base/layout/services/menu-config.service';

@Component({
	selector: 'kt-home-button',
	templateUrl: './home-button.component.html'
})
export class HomeButtonComponent {
	constructor(
		private router: Router,
		private menuConfigService: MenuConfigService,
		private menuService: MenuAsideService
	) {}

	redirectTo(): void {
		this.menuConfigService.getSlogan().subscribe(slogan => {
			localStorage.setItem('sloganAdi1', slogan[0].txtParametreDeger);
			localStorage.setItem('sloganAdi2', slogan[1].txtParametreDeger);
			this.menuService.getMenu().subscribe(datax => {
				let kontrol = datax.find(x => x.lngUstKod == null);
				if (kontrol) {
					if (kontrol.txtTargetUrl !== null && kontrol.txtTargetUrl !== '' && kontrol.txtTargetUrl !== undefined) {
						this.router.navigateByUrl('/' + kontrol.txtTargetUrl);
					} else {
						kontrol = datax.find(
							x =>
								x.txtTargetUrl !== null &&
								x.txtTargetUrl !== '' &&
								x.txtTargetUrl !== undefined &&
								x.lngUstKod === kontrol.lngKod
						);
						this.router.navigateByUrl('/' + kontrol.txtTargetUrl);
					}
				} else {
					if (localStorage.getItem('loginType') === 'plaka' || localStorage.getItem('loginType') === '') {
						this.router.navigateByUrl('/auth/login');
					} else {
						if (localStorage.getItem('loginType') == 'bayi') {
							this.router.navigateByUrl('/sorumlu-girisi');
						}
					}
				}
			});
		});
	}
}
