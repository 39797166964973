<img [src]="res" />
<button class="center-button pulse-effect" (click)="lokasyonAc()">
	<img [src]="imageSrc" />
	<!-- <a class="yazi pulse-effect">Lastik </a>
	<a class="yazi2 pulse-effect">Oteli</a>
	<a class="yazi3 pulse-effect">için</a>
	<a class="yazi4 pulse-effect">Brisa</a>
	<a class="yazi5 pulse-effect">Bayilerimizi</a>
	<a class="yazi6 pulse-effect">Seçiniz</a> -->
	<div style="font-size: 20px" class="yazi">
		<b>
			<p>Lastik Oteli</p>
			<p>için</p>
			<p>Brisa Bayilerimizi</p>
			<p>Seçiniz</p></b
		>
	</div>
</button>

<!-- Lastik Oteli için Brisa Bayilerimizi Seçiniz -->
