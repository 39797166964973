<div
	*ngIf="selectaktif && logintype !== 1"
	class="kt-header__topbar-item kt-header__topbar-item--quick-panel"
	data-placement="bottom"
	ngbTooltip="Filo Seçimi"
>
	<mat-form-field class="filtre1" appearance="outline" style="width: 100%">
		<mat-label>Filo Seçiniz</mat-label>
		<mat-select disableRipple [(ngModel)]="filovalue" (ngModelChange)="filoSecildi()">
			<mat-option *ngFor="let filo of filoListesi" value="{{ filo.Key }}">{{ filo.Value }}</mat-option>
		</mat-select>
	</mat-form-field>
</div>
