import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logout } from 'app/core/auth';
import { AppState } from 'app/core/reducers';

@Component({
	selector: 'kt-yeni-plaka',
	templateUrl: './yeni-plaka.component.html'
})
export class YeniPlakaComponent implements OnInit {
	plakaGoster = false;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>) {}

	ngOnInit(): void {
		if (localStorage.getItem('loginType') === 'plaka') {
			this.plakaGoster = true;
		} else {
			this.plakaGoster = false;
		}
	}

	redirectTo(): void {
		localStorage.clear();
		this.store.dispatch(new Logout());
	}
}
