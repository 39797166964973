import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
@Injectable()
export class LoaderService {
	constructor(private router: Router) {}

	isLoading = new Subject<boolean>();
	show() {
		this.isLoading.next(true);
	}
	hide() {
		this.isLoading.next(false);
	}
}
