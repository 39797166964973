// Anglar
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
	ContentAnimateDirective,
	FirstLetterPipe,
	GetObjectPipe,
	HeaderDirective,
	JoinPipe,
	MenuDirective,
	OffcanvasDirective,
	SafePipe,
	ScrollTopDirective,
	SparklineChartDirective,
	StickyDirective,
	TabClickEventDirective,
	TimeElapsedPipe,
	ToggleDirective,
} from './_base/layout';
import { ConfigService, configFactory } from './config.service';
import { ErrorComponent } from './auth/error/error/error.component';
import { ArventoerrComponent } from './auth/error/arvento-error/arventoerr/arventoerr.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BayilokasyondialogComponent } from './auth/error/arvento-error/bayilokasyondialog/bayilokasyondialog.component';
import { ArventoModule } from './auth/error/arvento-error/arvento.module';

@NgModule({
	imports: [CommonModule, ArventoModule],
	declarations: [
		// directives
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		SparklineChartDirective,
		ContentAnimateDirective,
		StickyDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		ErrorComponent
	],
	exports: [
		// directives
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		SparklineChartDirective,
		ContentAnimateDirective,
		StickyDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: configFactory,
			deps: [ConfigService],
			multi: true
		}
	]
})
export class CoreModule { }
