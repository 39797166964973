import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderCompComponent } from './loader-comp.component';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [LoaderCompComponent],
	exports: [LoaderCompComponent],
	imports: [
		CommonModule,
		MatProgressSpinnerModule,
		RouterModule.forChild([
			{
				path: '',
				component: LoaderCompComponent
			}
		])
	]
})
export class LoaderCompModule {}
