// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from 'app/core/reducers';
import { isLoggedIn } from '../_selectors/auth.selectors';
import { MenuAsideService } from 'app/core/_base/layout';
import { Toaster } from 'ngx-toast-notifications';
import { AuthService } from '../_services/auth.service';

declare let $: any;
@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private store: Store<AppState>,
		private toaster: Toaster,
		private menuService: MenuAsideService,
		private authService: AuthService
	) {}

	showToast(text, caption, type) {
		this.toaster.open({
			text,
			caption,
			type
		});
	}

	urlKontrol(currentUrl: string, targetUrl: string): boolean {
		if (!targetUrl) {
			return false;
		}
		currentUrl = currentUrl.substring(1);
		let yasak = false;
		targetUrl.split('/').forEach((item, index) => {
			if (currentUrl.split('/')[index] !== item) {
				yasak = true;
			}
		});
		return !yasak;
		// const reMapCurrent = currentUrl.substring(1).split("/").slice(0, 2).join("/");
		// const reMapTarget = targetUrl.indexOf('/:') === -1 ? targetUrl.substring(0, targetUrl.indexOf('/:')) : targetUrl

		// const parametreLokasyon = targetUrl.indexOf('/:');
		// if (parametreLokasyon === -1) {
		// 	return currentUrl === targetUrl;
		// } else {
		// 	return currentUrl.substring(0, parametreLokasyon) === targetUrl.substring(0, parametreLokasyon);
		// }
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store.pipe(
			select(isLoggedIn),
			tap(loggedIn => {
				if (loggedIn) {
					if (!this.authService.isAuthenticated()) {
						this.showToast('', 'Kullanım Süresi Doldu.Tekrar giriş yapınız', 'danger');
						this.authService.logout();
					}

					if (this.menuService.UserMenuItems.length === 0) {
						this.menuService.getMenu().subscribe(() => {
							if (this.menuService.UserMenuItems.length === 0) {
								this.authService.logout();
							}
							if (!this.menuService.UserMenuItems.some(x => this.urlKontrol(state.url, x.txtTargetUrl))) {
								this.authService.logout();
							}
						});
					} else {
						if (!this.menuService.UserMenuItems.some(x => this.urlKontrol(state.url, x.txtTargetUrl))) {
							this.authService.logout();
						}
					}
				} else {
					this.authService.logout();
				}
			})
		);
	}
}
