// Angular
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
// Core Module
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTabsModule } from '@angular/material/tabs';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { AgGridModule } from 'ag-grid-angular';
import { MatRippleModule } from '@angular/material/core';
import { BayilokasyondialogComponent } from './bayilokasyondialog/bayilokasyondialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { SearchPipeModule } from 'app/views/pages/randevu/lokasyon-ekleme/search-pipes/search-pipe.module';
import { ArventoerrComponent } from './arventoerr/arventoerr.component';

const routes: Routes = [];

@NgModule({
	imports: [
		MatAutocompleteModule,
		MatListModule,
		MatRadioModule,
		CommonModule,
		//PartialsModule,
		//CoreModule,
		//RouterModule.forChild(routes),
		AgGridModule.withComponents([]),
		ReactiveFormsModule,
		FormsModule,
		MatSlideToggleModule,
		MatIconModule,
		MatTableModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatButtonModule,
		NgbModule,
		MatTooltipModule,
		MatCheckboxModule,
		MatTableModule,
		MatListModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		MatMenuModule,
		MatCardModule,
		MatProgressSpinnerModule,
		SweetAlert2Module.forRoot(),
		MatPaginatorModule,
		MatTreeModule,
		MatExpansionModule,
		NgxMatSelectSearchModule,
		MatTabsModule,
		DragDropModule,
		MatDialogModule,
		MatGridListModule,
		MatDividerModule,
		MatRippleModule,
		DragDropModule,
		MatAutocompleteModule,
		MatChipsModule,
		SearchPipeModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [],
	declarations: [ArventoerrComponent, BayilokasyondialogComponent]
})
export class ArventoModule {}
