<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
	<div
		class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
	>
		<!--begin::Aside-->
		<!-- <div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside" style="background-image: url(./assets/media//bg/bg-4.jpg);"> -->
		<!-- <div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside" style="width: 0px;background-image: url(./assets/media//bg/login_bg.webp); background-size: cover; background-repeat: no-repeat; background-position-y: bottom; background-position-x: center;">
		
			<div class="kt-grid__item">
				<a href="javascript:;" class="kt-login__logo">
					<img src="./assets/media/logos/brisa-logo.png" style="width: 190px;"  alt="">
				</a>
			</div>
			<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
				<div class="kt-grid__item kt-grid__item--middle">
				
				</div>
			</div>
			<div class="kt-grid__item">
				<div class="kt-login__info">
					<div class="kt-login__copyright">
						© {{today|date:'yyyy'}} Brisa Bridgestone Sabancı
					</div>
				</div>
			</div>
		</div> -->
		<!--begin::Aside-->

		<!--begin::Content-->
		<!-- <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper" style="background-image: url(./assets/media//bg/login_bg.webp); background-size: cover; background-repeat: no-repeat; background-position-y: bottom; background-position-x: right;"> -->
		<div
			class="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
			style="
				height: 100%;
				background-image: url(./assets/media/bg/login_bg.webp);
				background-size: cover;
				background-repeat: no-repeat;
				background-position-y: bottom;
				background-position-x: right;
			"
		>
			<router-outlet></router-outlet>
		</div>
		<!--end::Content-->
	</div>
</div>
