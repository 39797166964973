// Angular
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatIconModule } from '@angular/material/icon';

import dayGridPlugin from '@fullcalendar/daygrid';
import { AgGridModule } from 'ag-grid-angular';

import { OverlayModule } from '@angular/cdk/overlay';

// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, DefaultRouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './views/theme/theme.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';

// Layout Services
import {
	DataTableService,
	KtDialogService,
	LayoutConfigService,
	LayoutRefService,
	MenuAsideService,
	MenuConfigService,
	MenuHorizontalService,
	PageConfigService,
	SplashScreenService,
	SubheaderService
} from './core/_base/layout';

// Auth
import { AuthModule } from './views/pages/auth/auth.module';
// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from './core/_base/crud';
import { AppUpdateService } from './core/_base/crud/utils/app-update.service';

// Config
import { LayoutConfig } from './core/_config/layout.config';

import { LoaderCompModule } from '../app/core/_base/crud/loader-comp/loader-comp.module';

//Interceptor
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from './core/_base/crud/utils/intercept.service';
import { LoaderInterCeptService } from './core/_base/crud/utils/loader-intercept.service';
import { LoaderService } from '../app/core/_base/crud/utils/loader.service';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TreeTableModule } from 'primeng/treetable';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './core/messaging.service';

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

export function tokenGetter(): string {
	return localStorage.getItem(environment.authTokenKey);
}
FullCalendarModule.registerPlugins([
	// register FullCalendar plugins
	dayGridPlugin
]);
@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgxPermissionsModule.forRoot(),
		PartialsModule,
		CoreModule,
		MatTabsModule,
		OverlayModule,
		MatIconModule,
		TreeTableModule,
		FullCalendarModule,
		MatGridListModule,
		MatSelectModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer, stateKey: 'router' }),
		StoreDevtoolsModule.instrument(),
		AuthModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(),
		ThemeModule,
		LoaderCompModule,
		ToastNotificationsModule,
		JwtModule.forRoot({
			config: {
				tokenGetter
			}
		}),
		ServiceWorkerModule.register('ngsw-worker.js'),
		// ServiceWorkerModule.register('app-update.service.js'),
		AgGridModule.withComponents([]),
		AngularFireModule,
		AngularFireModule.initializeApp(environment.firebase)
	],
	exports: [MatIconModule],
	providers: [
		LoaderService,
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		[{ provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true }],
		[{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterCeptService, multi: true }],
		[{ provide: DEFAULT_CURRENCY_CODE, useValue: 'TRY' }],
		// AuthService,
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		MessagingService,
		SplashScreenService,
		{
			provide: SwRegistrationOptions,
			useFactory: () => ({ enabled: environment.production })
		},
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService],
			multi: true
		},
		// template services
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		AppUpdateService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
