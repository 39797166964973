// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
// Services
import { MenuMasterYetkiResponse, MenuMasterYetkiResponseV2 } from '../models/menu-master-yetki.response';
import { ConfigService } from 'app/core/config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Toaster } from 'ngx-toast-notifications';
import { Router } from '@angular/router';

class ASideMenuItem {
	title: string;
	root: boolean;
	icon: string;
	hidden: boolean;
	page: string;
	submenu: ASideMenuItem[];
	parentId: number;
	id: number;

	constructor(item: MenuMasterYetkiResponse) {
		this.title = item.txtAciklama;
		this.root = true;
		this.icon = item.txtClassIcon;
		this.hidden = item.bytGoster === 0;
		this.page = item.txtTargetUrl ? '/' + item.txtTargetUrl : null;
		this.submenu = [];
		this.parentId = item.lngUstKod;
		this.id = item.lngKod;
	}
}

@Injectable()
export class MenuAsideService {
	// Public properties
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	private userMenuItems: MenuMasterYetkiResponse[] = [];
	firstElementUrl: string;
	/**
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 */
	constructor(
		private http: HttpClient,
		private configService: ConfigService,
		private toaster: Toaster,
		private router: Router
	) {}

	private showToast(text, caption, type) {
		this.toaster.open({
			text,
			caption,
			type
		});
	}

	UserMenu = new BehaviorSubject(this.UserMenuItems);
	set UserMenuItems(value: MenuMasterYetkiResponse[]) {
		this.userMenuItems = value;
		this.loadMenu();
		this.UserMenu.next(value);
	}

	get UserMenuItems(): MenuMasterYetkiResponse[] {
		return this.userMenuItems;
	}

	/**
	 * Load menu list
	 */

	private loadMenu() {
		const defaults = {
			header: {
				self: {},
				items: []
			},
			aside: {
				self: {},
				items: []
			}
		};

		this.userMenuItems = this.userMenuItems.sort(o => o.lngSira);

		const aSideMenuItems: ASideMenuItem[] = [];

		this.userMenuItems.forEach(item => {
			aSideMenuItems.push(new ASideMenuItem(item));
		});

		const idMapping = aSideMenuItems.reduce((acc, el, i) => {
			acc[el.id] = i;
			return acc;
		}, {});

		const aSideMenu = [];
		aSideMenuItems.forEach(el => {
			const parentEl = aSideMenuItems[idMapping[el.parentId]];

			if (el.parentId === null || !parentEl) {
				aSideMenu.push(el);
				if (el.submenu.length === 0) {
					el.submenu = null;
				}

				return;
			}

			parentEl.submenu = [...(parentEl.submenu || []), el];
			if (el.submenu.length === 0) {
				el.submenu = null;
			}
		});

		this.searchItem(aSideMenu);
		defaults.aside.items = aSideMenu;
		this.menuList$.next(defaults.aside.items);
	}

	private searchItem(menuItems) {
		menuItems.forEach(item => {
			if (this.firstElementUrl) {
				return;
			}
			if (item.page) {
				this.firstElementUrl = item.page;
			} else {
				this.searchItem(item.submenu);
			}
		});
	}

	getMenu(): Observable<MenuMasterYetkiResponse[]> {
		// if (this.CurrentUser().Id) {
		return this.http.get<MenuMasterYetkiResponseV2[]>('api/Kullanici/MenuYetki').pipe(
			map(val => {
				if (val.length === 0) {
					if (window.location.search.includes('?q=')) {
						throw new Error('');
					} else {
						throw this.showToast('', 'Menü yetkiniz bulunmamaktadır.', 'danger');
					}
				}

				const retVal: MenuMasterYetkiResponse[] = [];
				val.forEach(element => {
					retVal.push(new MenuMasterYetkiResponse(element));
				});

				if (retVal && retVal.some(f => f.txtTargetUrl)) {
					this.UserMenuItems = retVal;
				}

				return retVal;
			})
		);
	}

	/**
	 * @description Sayfa URL'si gönderilirse yetkisini kontrol ederek ilgili sayfaya yönlendirir.
	 * yetkisi olmayan sayfaya yönlendirilirse sorumlu giriş ekranına döner.
	 * url belirtilmezse menüdeki yetkisi olan ilk sayfaya yönlenir.
	 *
	 * @example
	 * ```ts
	 * this.menuService.navigate();
	 * this.menuService.navigate('lastik-arama');
	 * ```
	 */
	navigate(url?: string): void {
		if (url) {
			if (this.userMenuItems.some(f => f.txtTargetUrl === url)) {
				this.router.navigate([url]);
				return;
			}
		}

		if (
			window.location.hostname &&
			window.location.hostname === 'performanslastikara.com' &&
			this.userMenuItems.some(f => f.txtTargetUrl === 'performans-lastik-arama')
		) {
			this.router.navigate(['/performans-lastik-arama']);
			return;
		}

		if (this.userMenuItems.some(f => '/' + f.txtTargetUrl === this.firstElementUrl)) {
			this.router.navigate([this.firstElementUrl]);
			return;
		}

		this.router.navigate(['/auth/sorumlu-girisi']);
	}
}
