<div class="kt-header__topbar">
	<div class="hidden-xs hidden-sm">
		<kt-filo-selectbox></kt-filo-selectbox>
	</div>

	<kt-yeni-plaka></kt-yeni-plaka>
	<kt-home-button></kt-home-button>

	<kt-logout></kt-logout>
	<kt-user-profile></kt-user-profile>
</div>
