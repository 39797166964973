import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'kt-logout',
	templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {
	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private router: Router) {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	ngOnInit() {}

	logout(): void {
		localStorage.getItem('sloganAdi');
		const loginType = localStorage.getItem('loginType');
		const sloganAdi = localStorage.getItem('sloganAdi');
		localStorage.clear();
		localStorage.removeItem('accessToken');
		// localStorage.setItem('sloganAdi', sloganAdi);
		// localStorage.setItem('sloganAdi',sloganAdi);
		//this.store.dispatch(new Logout());
		window.location.reload();
	}
}
