export class MenuMasterYetkiResponse {
	lngKullaniciGrupKod: number;
	txtTipAd: string;
	lngKod: number;
	lngUstKod?: number;
	lngSira: number;
	txtAciklama: string;
	txtTargetUrl: string;
	txtClassIcon: string;
	bytGoster: number;

	constructor(item: MenuMasterYetkiResponseV2) {
		this.lngKod = item.kod;
		this.lngUstKod = item.ustKod;
		this.lngSira = item.sira;
		this.txtAciklama = item.aciklama;
		this.txtTargetUrl = item.targetUrl;
		this.txtClassIcon = item.classIcon;
		this.bytGoster = item.bytGoster;
	}
}

export class MenuMasterYetkiResponseV2 {
	kod: number;
	ustKod?: number;
	sira: number;
	aciklama: string;
	targetUrl: string;
	classIcon: string;
	bytGoster: number;
}
