<div
	class="kt-header__topbar-item kt-header__topbar-item--quick-panel"
	style="margin: auto 4px; font-size: 20px; margin: 0 8px 0 8px"
	data-placement="bottom"
	ngbTooltip="Anasayfa"
	(click)="redirectTo()"
>
	<span class="kt-header__topbar-icon">
		<i class="fas fa-home"></i>
	</span>
</div>
