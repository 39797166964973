import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from 'app/core/config.service';
import { LoginRequest } from '../_models/login/login.request';
import { KullaniciKayitRequest } from '../_models/login/register.request';
import { KullaniciKayitResponse } from '../_models/login/register.response';

import { PanaromaLoginRequest } from '../_models/login/panaromalogin.request';
import { PanaromaLoginResponse } from '../_models/login/panaromaLogin.response';
import { LoginResponse } from '../_models/login/login.response';

@Injectable()
export class LoginService {
	constructor(private http: HttpClient, private configService: ConfigService) {}

	// #region Eski sistem yeni token mekanizması testleri bittiğinde kaldırılacak
	plakaLogin(txtPlaka: string): Observable<LoginResponse> {
		const req: LoginRequest = { txtPlaka, kullaniciAd: '', sifre: '' };
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http.post<LoginResponse>(`${this.configService.config.apiUrl}api/Kullanici/AracKullaniciGiris`, req, {
			headers
		});
	}

	sorumluGirisi(req: LoginRequest): Observable<PanaromaLoginResponse> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http.post<PanaromaLoginResponse>(
			`${this.configService.config.apiUrl}api/Kullanici/SorumluGirisi`,
			req,
			{ headers }
		);
	}

	panoramaGirisi(req: PanaromaLoginRequest): Observable<PanaromaLoginResponse> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http.post<PanaromaLoginResponse>(
			`${this.configService.config.apiUrl}api/Kullanici/PanoramaGirisi`,
			req,
			{ headers }
		); // panaromadan girerken url alması için
	}

	kullaniciKayit(req: KullaniciKayitRequest): Observable<KullaniciKayitResponse> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http.post<KullaniciKayitResponse>(
			`${this.configService.config.apiUrl}api/Kullanici/KullaniciKayit`,
			req,
			{ headers }
		);
	}

	getLogoFind(subdomain: string): Observable<any> {
		const params = new HttpParams().set('subdomain', subdomain);
		return this.http.get<any>(this.configService.config.apiUrl + 'api/Login/LogoFind', {
			params: params
		});
		// #endregion
	}
}
