import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'app/core/config.service';
import { LayoutConfigService } from 'app/core/_base/layout';
import * as objectPath from 'object-path';

@Component({
	selector: 'kt-footer',
	templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
	public today: number = Date.now();
	public fluid: boolean;
	public version: string;
	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayouConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService, private configService: ConfigService) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();

		// footer width fluid
		this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
		this.version = this.configService.config.version;
	}
}
