import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
	providedIn: 'root'
})
export class AppUpdateService {
	constructor(private readonly updates: SwUpdate) {
		this.updates.available.subscribe(event => {
			this.guncellestirmeGoster();
		});
	}
	private async guncellestirmeGoster() {
		// const alert = this.alertCtrl.create({
		// 	header: 'Mevcut Güncelleştirmeler Var',
		// 	subHeader: 'Güncellemek istiyor musunuz?',
		// 	buttons: [
		// 		{
		// 			text: 'Güncelle',
		// 			role: 'update',
		// 			handler: () => {
		// 				this.guncelle();
		// 			}
		// 		},
		// 		{
		// 			text: 'Kapat',
		// 			role: 'cancel',
		// 			handler: () => {
		// 				this.kapat();
		// 			}
		// 		}
		// 	]
		// });
		// (await alert).present();
	}
	private kapat() {
		//this.alertCtrl.dismiss();
	}
	private guncelle() {
		this.updates.activateUpdate().then(() => document.location.reload());
	}
}
