import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuConfigService } from 'app/core/_base/layout/services/menu-config.service';
import { enuUserType, LoginV2Request } from 'app/core/auth/_models/login/loginV2.request';
import { AuthService } from 'app/core/auth/_services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuAsideService } from 'app/core/_base/layout';
import { Subject } from 'rxjs';
import { AuthNoticeService } from 'app/core/auth';
declare let $: any;

@Component({
	selector: 'kt-sorumlu-login',
	templateUrl: './sorumlugirisi.component.html',
	// styleUrls: ['./sorumlugirisi.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SorumluGirisiLoginComponent implements OnInit {
	public today: number = Date.now();
	public hizmetNoktasiForm: FormGroup;
	private loading = false;
	private exampleTabset: {
		beforeCodeTitle: string;
		viewCode: string;
		isCodeVisible: boolean;
		isExampleExpanded: boolean;
	};
	private tabset = { beforeCodeTitle: 'Tabset', viewCode: ``, isCodeVisible: false, isExampleExpanded: true };

	constructor(
		private menuConfigService: MenuConfigService,
		private fb: FormBuilder,
		private authService: AuthService,
		private router: Router,
		private snackBar: MatSnackBar,
		private menuService: MenuAsideService,
		private authNoticeService: AuthNoticeService
	) {
		this.unsubscribe = new Subject();
	}
	private unsubscribe: Subject<any>;
	ngOnDestroy(): void {
		$('.modal').modal('hide');
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	ngOnInit(): void {
		this.hizmetNoktasiForm = this.fb.group({
			kullaniciAdi: ['', Validators.compose([Validators.required])],
			sifre: ['', Validators.compose([Validators.required])]
		});
		this.exampleTabset = this.tabset;
	}

	isHizmetNoktasiControlHasError(controlName: string, validationType: string): boolean {
		const control = this.hizmetNoktasiForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	sorumluGirisiSubmit(): boolean {
		const controls = this.hizmetNoktasiForm.controls;
		if (this.hizmetNoktasiForm.invalid) {
			Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
			return;
		}
		this.loading = true;

		const authDatav2: LoginV2Request = {
			UserName: controls.kullaniciAdi.value,
			Password: controls.sifre.value,
			UserType: enuUserType.PanoramaKullanici
		};

		this.authService.login(authDatav2).subscribe(() => {
			this.menuConfigService.getSlogan().subscribe(slogan => {
				localStorage.setItem('sloganAdi1', slogan[0].txtParametreDeger);
				localStorage.setItem('sloganAdi2', slogan[1].txtParametreDeger);
			});
			this.menuService.getMenu().subscribe(() => {
				this.menuService.navigate();
			});
		});

		return false;
	}

	kayitol(): void {
		this.router.navigateByUrl('/auth/register');
	}

	openSnackBar(message: string, action: string, className: string): void {
		this.snackBar.open(message, action, {
			duration: 2000,
			verticalPosition: 'bottom',
			horizontalPosition: 'end',
			panelClass: [className]
		});
		// $('.mat-snack-bar-container').addClass(className);
	}
}
