<!--begin::Head-->
<!-- <div class="kt-login__head">
	<div ngbDropdown class="d-inline-block" placement="bottom-right">
		<button class="btn btn-primary" id="dropdownBasic1" ngbDropdownToggle>
			<i class="fas fa-bars"></i>
		</button>
		<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
			<a routerLink="/auth/login" class="dropdown-item">Plaka Girişi</a>
			<a routerLink="/auth/filo-girisi" class="dropdown-item">Filo Girişi</a>
			<a routerLink="/auth/hizmet-noktasi-girisi" class="dropdown-item">Hizmet Noktası Girişi</a>
			<a routerLink="/auth/register" class="dropdown-item">Kayıt Ol</a>
		</div>
	</div>
</div> -->

<div class="kt-login__head">
	<a
		routerLink="/auth/sorumlu-girisi"
		id="kt_sorumlu_girisi_submit"
		class="btn btn-light"
		style="padding-top: 15px; padding-bottom: 15px; font-family: Roboto, 'Helvetica Neue', sans-serif"
		>Kurumsal Kullancı Girişi</a
	>
</div>
<div class="kt-login__head"></div>
<!--end::Head-->
<!--begin::Body-->
<div class="kt-login__body">
	<!--begin::Signin-->
	<div class="kt-login__form panel p-4" style="max-width: 300px">
		<a href="javascript:;" class="kt-login__logo" *ngIf="imageBase64 == null">
			<img src="./assets/media/logos/brisa-logo.png" style="width: 100%; margin-top: 0px" alt="" />
		</a>
		<div class="kt-login__logo" style="max-width: 270px; display: flex; justify-content: center">
			<img [src]="imageBase64" style="max-width: 270px; display: flex; justify-content: center" />
		</div>
		<div class="kt-login__title">
			<h3>
				Online Randevu Sistemine<br />
				Hoş Geldiniz
			</h3>
		</div>

		<!-- <kt-auth-notice></kt-auth-notice> -->

		<kt-material-preview [viewItem]="exampleTabset">
			<div class="kt-section">
				<div class="kt-section__content">
					<form class="kt-form" [formGroup]="plakaForm" autocomplete="off">
						<div class="form-group">
							<mat-form-field appearance="outline">
								<mat-label>Plaka Giriniz</mat-label>
								<input
									matInput
									type="text"
									oninput="this.value = this.value.toUpperCase()"
									(input)="plakaInput($event)"
									placeholder="Plaka Giriniz"
									formControlName="plaka"
									autocomplete="off"
								/>
								<mat-error *ngIf="isPlakaControlHasError('plaka', 'required')">
									<strong>{{ 'Alan boş geçilemez' | translate }}</strong>
								</mat-error>
								<mat-error *ngIf="isPlakaControlHasError('plaka', 'minlength')">
									<strong>{{ 'Minimum 5 karakter giriniz' | translate }} 3</strong>
								</mat-error>
								<mat-error *ngIf="isPlakaControlHasError('plaka', 'maxlength')">
									<strong>
										{{ 'Maksimum 9 karakter giriniz' | translate }}
									</strong>
								</mat-error>
								<mat-error *ngIf="isPlakaControlHasError('plaka', 'pattern')">
									<strong>
										{{ 'Geçersiz karakter girmeyiniz' | translate }}
									</strong>
								</mat-error>
							</mat-form-field>
						</div>
						<!--begin::Action-->
						<div class="kt-login__actions">
							<a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link kt-login__link-forgot"> </a>
							<button
								style="width: 100%; font-size: 18px"
								(click)="plakaSubmit()"
								[ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading }"
								id="kt_plaka_submit"
								class="btn btn-primary btn-elevate kt-login__btn-primary btnbtn"
								style="width: 100%; font-size: 18px; font-family: Roboto, 'Helvetica Neue', sans-serif"
							>
								Giriş
							</button>
						</div>
						<!--end::Action-->
					</form>
				</div>
			</div>
		</kt-material-preview>

		<div class="kt-grid__item">
			<div class="kt-login__info">
				<div class="kt-login__copyright loginn">© {{ today | date : 'yyyy' }} Brisa Bridgestone Sabancı</div>
			</div>
		</div>

		<!-- <kt-auth-notice></kt-auth-notice> -->

		<!--begin::Divider-->
		<!-- <div class="kt-login__divider">
			<div class="kt-divider">
				<span></span>
				<span>OR</span>
				<span></span>
			</div>
		</div> -->
		<!--end::Divider-->

		<!--begin::Options-->
		<!-- <div class="kt-login__options">
			<a href="https://www.facebook.com/keenthemes/" target="_blank" class="btn btn-primary kt-btn">
				<i class="fab fa-facebook-f"></i>
				Facebook
			</a>

			<a href="https://twitter.com/keenthemes/" target="_blank" class="btn btn-info kt-btn">
				<i class="fab fa-twitter"></i>
				Twitter
			</a>

			<a href="javascript:;" class="btn btn-danger kt-btn">
				<i class="fab fa-google"></i>
				Google
			</a>
		</div> -->
		<!--end::Options-->
	</div>
	<!--end::Signin-->
</div>
<!--end::Body-->
