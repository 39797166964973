// Angular
import { ModuleWithProviders, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
import { InterceptService } from '../../../core/_base/crud/';
// Module components
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
// Auth
import { AuthEffects, AuthGuard, authReducer } from '../../../core/auth';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../../core/auth/_services';
import { RegisterComponent } from './register/register.component';
import { PanaromaLoginComponent } from './panaroma-login/panaroma-login.component';
import { SorumluGirisiLoginComponent } from './sorumlugirisi/sorumlugirisi.component';

import { AuthService } from '../../../core/auth/_services/auth.service';
import { TokenLoginComponent } from './token-login/token-login.component';
const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: 'login',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'login/trendyol/:sipNo',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'sorumlu-girisi',
				component: SorumluGirisiLoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'panaroma-girisi',
				component: PanaromaLoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'panaroma-girisi/:id',
				component: PanaromaLoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'panaroma-girisi/:id/:id2',
				component: PanaromaLoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'token-login',
				component: TokenLoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'token-login/:id',
				component: TokenLoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'token-login/:id/:id2',
				component: TokenLoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: '',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname }
			}
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		RouterModule.forChild(routes),

		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects]),
		NgbModule
	],
	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		}
	],
	exports: [AuthComponent],
	declarations: [
		AuthComponent,
		LoginComponent,
		RegisterComponent,
		PanaromaLoginComponent,
		SorumluGirisiLoginComponent,
		TokenLoginComponent

		// ForgotPasswordComponent,
		// AuthNoticeComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModule {
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [AuthService, LoginService, AuthGuard]
		};
	}
}
