import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IlListesiResponse } from 'app/core/randevu/hizmet-noktasi/models/il-listesi.response';
import { LokasyonService } from 'app/core/randevu/hizmet-noktasi/services/lokasyon.service';
import { IlceListesiResponse } from 'app/core/randevu/hizmet-noktasi/models/ilce-listesi.response';
import { HizmetOneriBayiListesiRequest } from 'app/core/randevu/hizmet-noktasi/models/hizmet-oneri-bayi-listesi.request';
import { HizmetOneriBayiListesiResponse } from 'app/core/randevu/hizmet-noktasi/models/hizmet-oneri-bayi-listesi.response';
import { MatTableDataSource } from '@angular/material/table';
@Component({
	selector: 'kt-bayilokasyondialog',
	templateUrl: './bayilokasyondialog.component.html',
	styleUrls: ['./bayilokasyondialog.component.scss']
})
export class BayilokasyondialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef<BayilokasyondialogComponent>,
		private fb: FormBuilder,
		private lokasyonService: LokasyonService
	) {}
	public lokasyonForm: FormGroup;
	public duzenleniyor = false;
	public ilListesi: IlListesiResponse[] = [];
	public ilceListesi: IlceListesiResponse[] = [];
	public oneriBayiListesi = new MatTableDataSource<HizmetOneriBayiListesiResponse>();
	public displayedColumns: string[] = [
		'konum',
		'il',
		'ilce',
		'bayiIsim',
		'lokasyonIsim',
		'yetkiliMailAdresleri',
		'yetkiliCepTelAdresleri',
		'adres'
	];
	public filteredColumn = {
		ilceFilter: null,
		bayiAdFilter: null,
		lokasyonIsimFilter: null
	};
	public displayedFiltreColumns: string[] = [
		'yckFilter',
		'ilFilter',
		'ilceF',
		'bayiAdF',
		'lokasyonIsimF',
		'ymaFilter',
		'yctFilter',
		'ycaFilter'
	];
	@ViewChild('matButton', { read: ElementRef }) public matButton: ElementRef;
	onConfirmClick(): void {
		this.dialogRef.close(true);
	}
	ngOnInit(): void {
		this.getIlListesi();
		this.initLokasyonForm();
	}
	ngAfterViewInit(): void {
		this.matButton.nativeElement.click();
	}

	getWidthPercentage(): number {
		return 100 / this.displayedColumns.length;
	}

	getIletisim(lngBayiKod: number, bytTur: number): string {
		const filteredData = this.data.filter(item => item.lngBayiKod === lngBayiKod && item.iletisimParametreler);
		const txtDegerler = filteredData.map(item =>
			item.iletisimParametreler
				.filter(param => param.bytTur === bytTur)
				.map(param => param.txtDeger)
				.join(',')
		);
		return txtDegerler.join(',');
	}

	initLokasyonForm(): void {
		this.lokasyonForm = this.fb.group({
			lngIlKod: [null, Validators.compose([Validators.required])],
			lngIlceKod: [null],
			ilFilter: [null],
			ilceFilter: [null]
		});
	}
	isLokasyonControlHasError(controlName: string, validationType: string): boolean {
		const control = this.lokasyonForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
	getIlListesi(): void {
		this.lokasyonService.getIlListesi().subscribe(data => {
			this.ilListesi = data;
		});
	}
	getIlceListesi(lngIlKod: number): void {
		this.lokasyonService.getIlceListesi(lngIlKod).subscribe(data => {
			this.ilceListesi = data;
		});
	}

	ilceDegistir(lngIlKod: number): void {
		this.lokasyonForm.controls['lngIlceKod'].setValue(null);
		this.getIlceListesi(lngIlKod);
	}

	getOneriBayiList(lngIlKod: HizmetOneriBayiListesiRequest): void {
		this.lokasyonService.getHizmetOneriBayiListesi(lngIlKod).subscribe(data => {
			this.oneriBayiListesi.data = data;
		});
	}

	ara(): void {
		const controls = this.lokasyonForm.controls;
		if (this.lokasyonForm.invalid) {
			Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
			return;
		}
		const req: HizmetOneriBayiListesiRequest = {
			lngIlKod: this.lokasyonForm.controls['lngIlKod'].value,
			lngIlceKod: this.lokasyonForm.controls['lngIlceKod'].value
		};
		console.log(this.filteredColumn.ilceFilter);

		this.getOneriBayiList(req);
	}
}
