import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IlSearchPipe } from './il-search.pipe';
import { hoblTxtIlcePipe, hoblTxtLokasyonAdPipe, hoblTxtBayiAdPipe } from 'app/core/auth/error/pipes/hobl.pipe';
import { IlceSearchPipe } from './ilce-search.pipe';

@NgModule({
	declarations: [IlSearchPipe, IlceSearchPipe, hoblTxtIlcePipe, hoblTxtLokasyonAdPipe, hoblTxtBayiAdPipe],
	exports: [IlSearchPipe, IlceSearchPipe, hoblTxtIlcePipe, hoblTxtLokasyonAdPipe, hoblTxtBayiAdPipe],
	imports: [CommonModule]
})
export class SearchPipeModule {}
