import { Pipe, PipeTransform } from '@angular/core';
import { IlListesiResponse } from '../../../../../core/randevu/hizmet-noktasi/models/il-listesi.response';

@Pipe({
	name: 'ilSearch'
})
export class IlSearchPipe implements PipeTransform {
	transform(value: IlListesiResponse[], filterText?: string): IlListesiResponse[] {
		filterText = filterText ? filterText.toLocaleLowerCase() : null;
		return filterText
			? value.filter((d: IlListesiResponse) => d.txtIlAdi.toLocaleLowerCase().indexOf(filterText) !== -1)
			: value;
	}
}
