import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'app/core/config.service';
import { LoginV2Request } from '../_models/login/loginV2.request';
import { LoginResponseV2 } from '../_models/login/login.response';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppState } from 'app/core/reducers';
import { Store } from '@ngrx/store';
import { Login, Logout } from '../_actions/auth.actions';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AccessTokenResponse } from '../_models/login/access-token.response';
import { KullaniciTemplateResponse } from '../_models/kullaniciTemplate.response';
import { KullaniciTemplateRequest } from '../_models/kullaniciTemplate.request';
import { KullaniciTemplateKaydetRequest } from '../_models/kullaniciTemplateKaydet.request';
import { KullanıcıyaBagliFiloListesiResponese } from 'app/core/ofkas/models/response/kullaniciayarlari/kulanicibaglifilolistesi.response';
import { MessagingService } from 'app/core/messaging.service';
import { TrendyolRandevuObjResponse, TrendyolRandevuResponse } from 'app/core/randevu/models/trendyol-randevu-response';

export enum LoginType {
	plaka = 0,
	developer = 1,
	merkez = 2,
	bayi = 5,
	filo = 6,
	diğer = 7,
	OEPlaza = 12
}

class Token {
	public User: MiyaUser;
	public aud: string;
	public exp: number;
	public iss: string;
}

export class MiyaUser {
	public Id: number;
	public UserName: string;
	public FullName: string;
	public UserType: LoginType;
	public UserTypeText: string;
	public MemberOf: number[];
	public ExternalId: number;
}

class Dist {
	public lngKod: number;
	public txtUnvan: string;
	public txtDistKod: string;
	public txtKod: string;
}

@Injectable()
export class AuthService {
	private param: BehaviorSubject<boolean>;
	public param$: Observable<boolean>;
	constructor(
		private http: HttpClient,
		private configService: ConfigService,
		private jwtHelper: JwtHelperService,
		private store: Store<AppState>,
		private router: Router,
		private messagingService: MessagingService
	) {
		this.param = new BehaviorSubject<boolean>(false);
		this.param$ = this.param.asObservable();
	}

	private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
	public message;
	login(value: LoginV2Request): Observable<MiyaUser> {
		return this.http
			.post<LoginResponseV2>(this.configService.config.apiUrl + 'token', value, { headers: this.headers })
			.pipe(
				map(res => {
					// localStorage.clear();
					localStorage.setItem('accessToken', res.access_token.toString());
					const token: Token = this.jwtHelper.decodeToken(res.access_token.toString());
					this.store.dispatch(new Login({ authToken: res.access_token }));

					/*
				Token'dan gelen orjinal UserType değeri Miya framework login tipidir.
				UI tarafında bu mantığı kullanmıyor UI için mantığını değiştiriyoruz.
				MiyaKullanici = 0,
				Token = 1,
				PanoramaKullanici = 2,
				Plaka = 3
				*/
					let tip = 'plaka';
					token.User.UserType = LoginType.plaka;

					if (token.User.MemberOf.find(f => f === 1)) {
						tip = 'developer';
						token.User.UserType = LoginType.developer;
					}
					if (token.User.MemberOf.find(f => f === 2)) {
						tip = 'merkez';
						token.User.UserType = LoginType.merkez;
					}
					if (token.User.MemberOf.find(f => f === 5)) {
						tip = 'bayi';
						token.User.UserType = LoginType.bayi;
					}
					if (token.User.MemberOf.find(f => f === 7)) {
						tip = 'diğer';
						token.User.UserType = LoginType.diğer;
					}
					if (token.User.MemberOf.find(f => f === 6)) {
						tip = 'filo';
						token.User.UserType = LoginType.filo;
					}
					if (token.User.MemberOf.find(f => f === 12)) {
						tip = 'OEPlaza';
						token.User.UserType = LoginType.OEPlaza;
					}

					localStorage.setItem('User', JSON.stringify(token.User));

					if (tip !== 'plaka') {
						this.getDistInfo().subscribe(data => {
							if (data.length !== 0) {
								localStorage.setItem('lngDistKod', data[0].lngKod.toString());
								localStorage.setItem('unvan', data[0].txtUnvan);
								localStorage.setItem('distArray', JSON.stringify(data));
								this.distYetki();
							}
						});
					}

					localStorage.setItem('bayiyetkili', '0');
					localStorage.setItem('loginType', tip);
					if (tip === 'bayi') {
						this.messagingService.requestPermission();
						this.messagingService.receiveMessage();
						this.message = this.messagingService.currentMessage;
					}

					localStorage.setItem('txtPerformansAnlasma', 'user.txtPerformansAnlasma');

					return token.User;
				})
			);
	}

	distInfo(): JSON {
		return JSON.parse(localStorage.getItem('distArray'));
	}

	logout(): void {
		const cntloginType = localStorage.getItem('loginType');
		localStorage.clear();
		localStorage.setItem('loginType', cntloginType);

		this.store.dispatch(new Logout());

		if (window.location.hostname === 'performanslastikara.com') {
			this.router.navigateByUrl('/auth/sorumlu-girisi');
		} else {
			if (cntloginType === 'plaka' || !cntloginType) {
				this.router.navigateByUrl('/auth/login');
			} else {
				this.router.navigateByUrl('/auth/sorumlu-girisi');
			}
		}
	}

	CurrentUser(): MiyaUser {
		const userStr = localStorage.getItem('User');
		let user = new MiyaUser();
		if (userStr) {
			user = JSON.parse(userStr) as MiyaUser;
		}
		user.ExternalId = +user.ExternalId;
		user.Id = +user.Id;
		return user;
	}

	getDistInfo(): Observable<Dist[]> {
		return this.http.get<Dist[]>(this.configService.config.apiUrl + 'api/kullanici/GetDistInfo', {
			headers: this.headers
		});
	}
	postTrendyolRandevuOku(vasId: string): Observable<TrendyolRandevuObjResponse> {
		const formData = new FormData();
		formData.append('vasId', vasId.toString());
		return this.http.post<TrendyolRandevuObjResponse>(
			this.configService.config.apiUrl + 'api/ETicaretEntegrasyon/TrendyolRandevuOku',
			formData
		);
	}

	distYetki(): Dist[] {
		const accessTokenexp: AccessTokenResponse = jwt_decode(localStorage.getItem('accessToken'));

		const eslesenDist: Dist[] = [];
		const distArray: Dist[] = JSON.parse(localStorage.getItem('distArray'));
		accessTokenexp.User.MemberOf.forEach(x => {
			distArray.forEach(dist => {
				if (x === dist.lngKod) {
					eslesenDist.push(dist);
				}
			});
		});
		return eslesenDist;
	}

	public isAuthenticated(): boolean {
		const token = localStorage.getItem('accessToken');
		return !this.jwtHelper.isTokenExpired(token);
	}

	private requestOptions = new HttpHeaders({
		'Content-Type': 'application/json',
		Authorization: 'bearer' + ' ' + localStorage.getItem('accessToken'),
		Accept: 'application/json'
	});

	private options = { headers: this.requestOptions };

	postKullaniciTemplate(req: KullaniciTemplateRequest) {
		return this.http.post<KullaniciTemplateResponse>(
			this.configService.config.apiUrl + 'api/Kullanici/KullaniciTemplate',
			req,
			this.options
		);
	}

	postKullaniciTemplateKaydet(req: KullaniciTemplateKaydetRequest) {
		return this.http.post<any>(
			this.configService.config.apiUrl + 'api/Kullanici/KullaniciTemplateKaydet',
			req,
			this.options
		);
	}
	KullaniciyaBagliFiloListesi(): Observable<KullanıcıyaBagliFiloListesiResponese[]> {
		return this.http.get<KullanıcıyaBagliFiloListesiResponese[]>(
			this.configService.config.apiUrl + 'api/OfkFilo/KullaniciyaBagliFiloListesi',
			{}
		);
	}
	kullaniciTipi(parametre: boolean): boolean {
		this.param.next(parametre);

		return parametre;
	}
}
