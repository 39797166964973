import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UrunKodunaGoreAramaRequest } from '../models/urun-koduna-gore-arama.request';
import { UrunKodunaGoreAramaResponse } from '../models/urun-koduna-gore-arama.response';
import { UrunKriterlerleriyleUrunKodGetirResponse } from '../models/urun-kriterleriyle-urun-kod-getir.response';

import { UrunAramaKriterleriResponse } from '../models/urun-arama-kriterleri.response';
import { TopOnListesiResponse } from '../models/topOnListesiResponse';
import { ConfigService } from '../../../../core/config.service';

@Injectable({
	providedIn: 'root'
})
export class PerformansLastikAramaService {
	constructor(private http: HttpClient, private configService: ConfigService) {}

	postUrunKodunaGoreArama(req: UrunKodunaGoreAramaRequest): Observable<UrunKodunaGoreAramaResponse> {
		return this.http.post<UrunKodunaGoreAramaResponse>(
			this.configService.config.apiUrl + 'api/PerformansLastik/UrunKodunaGoreArama',
			req,
			{}
		);
	}
	getUrunKriterleriyleUrunKodGetir(
		TxtAracTipi?: string,
		TxtRFT?: string,
		TxtMevsim?: string,
		TxtEbatAdi?: string,
		TxtMarka?: string
	): Observable<UrunKriterlerleriyleUrunKodGetirResponse[]> {
		const params = new HttpParams()
			.set('TxtAracTipi', TxtAracTipi === null ? '' : TxtAracTipi)
			.set('TxtRFT', TxtRFT === null ? '' : TxtRFT)
			.set('TxtMevsim', TxtMevsim === null ? '' : TxtMevsim)
			.set('TxtEbatAdi', TxtEbatAdi === null ? '' : TxtEbatAdi)
			.set('TxtMarka', TxtMarka === null ? '' : TxtMarka);
		return this.http.get<UrunKriterlerleriyleUrunKodGetirResponse[]>(
			this.configService.config.apiUrl + 'api/PerformansLastik/UrunKriteriIleUrunKodGetir',
			{
				params: params
			}
		);
	}

	getAramaKriterleriListesi(
		TxtAracTipi?: string,
		TxtRFT?: string,
		TxtMevsim?: string,
		TxtEbatAdi?: string,
		TxtMarka?: string
	): Observable<UrunAramaKriterleriResponse> {
		const params = new HttpParams()
			.set('TxtAracTipi', TxtAracTipi === null ? '' : TxtAracTipi)
			.set('TxtRFT', TxtRFT === null ? '' : TxtRFT)
			.set('TxtMevsim', TxtMevsim === null ? '' : TxtMevsim)
			.set('TxtEbatAdi', TxtEbatAdi === null ? '' : TxtEbatAdi)
			.set('TxtMarka', TxtMarka === null ? '' : TxtMarka);
		return this.http.get<UrunAramaKriterleriResponse>(
			this.configService.config.apiUrl + 'api/PerformansLastik/AramaKriterleriListesi',
			{
				params: params
			}
		);
	}

	getTopOnListesi(): Observable<TopOnListesiResponse[]> {
		return this.http.get<TopOnListesiResponse[]>(
			this.configService.config.apiUrl + 'api/PerformansLastik/PerformansLastikTopOn',
			{}
		);
	}
}
