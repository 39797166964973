import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthNoticeService } from 'app/core/auth';
// Store
import { MenuConfigService } from 'app/core/_base/layout/services/menu-config.service';
import { enuUserType, LoginV2Request } from 'app/core/auth/_models/login/loginV2.request';
import { AuthService } from 'app/core/auth/_services/auth.service';
import { MenuAsideService } from 'app/core/_base/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'kt-token-login',
	templateUrl: './token-login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class TokenLoginComponent implements OnInit, OnDestroy {
	txtKullaniciAd: string;
	txtAccessToken: string;
	constructor(
		private route: ActivatedRoute,
		private menuConfigService: MenuConfigService,
		private authService: AuthService,
		private menuService: MenuAsideService,
		private authNoticeService: AuthNoticeService
	) {
		this.unsubscribe = new Subject();
		this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
			this.txtKullaniciAd = params.UserName;
			this.txtAccessToken = params.AccessToken;
		});
	}

	hizmetNoktasiForm: FormGroup;
	loading = false;
	private unsubscribe: Subject<any>;

	ngOnInit(): void {
		localStorage.clear();

		let hangisayfa: string;
		this.route.url.pipe(takeUntil(this.unsubscribe)).subscribe(url => {
			if (url.length === 2) {
				hangisayfa = url[1].path;
			} else {
				hangisayfa = url[1].path + '/' + url[2].path;
			}
		});

		const authDatav2: LoginV2Request = {
			UserName: encodeURIComponent(this.txtAccessToken),
			Password: this.txtKullaniciAd,
			UserType: enuUserType.MiyaToken
		};

		this.authService
			.login(authDatav2)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(() => {
				this.menuConfigService
					.getSlogan()
					.pipe(takeUntil(this.unsubscribe))
					.subscribe(slogan => {
						localStorage.setItem('sloganAdi1', slogan[0].txtParametreDeger);
						localStorage.setItem('sloganAdi2', slogan[1].txtParametreDeger);
					});
				this.menuService
					.getMenu()
					.pipe(takeUntil(this.unsubscribe))
					.subscribe(() => {
						this.menuService.navigate(hangisayfa);
					});
			});
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
}
