<!-- begin::Sticky Toolbar -->
<ul class="kt-sticky-toolbar" style="margin-top: 30px">
	<li
		class="kt-sticky-toolbar__item kt-sticky-toolbar__item--success"
		id="kt_demo_panel_toggle"
		data-placement="left"
		ngbTooltip="Check out more demos"
	>
		<a href="javascript:;" class="">
			<!-- <i class="fa fa-bars"></i> -->
			T<br />O<br />P<br /><br />1<br />0
		</a>
	</li>
	<!-- <li class="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand" data-placement="left" ngbTooltip="Layout Builder">
		<a href="javascript:;" routerLink="/builder"><i class="flaticon2-gear"></i></a>
	</li>
	<li class="kt-sticky-toolbar__item kt-sticky-toolbar__item--warning" data-placement="left" ngbTooltip="Documentation">
		<a href="https://keenthemes.com/metronic/?page=docs" target="_blank"><i class="flaticon2-telegram-logo"></i></a>
	</li> -->
</ul>
<!-- end::Sticky Toolbar -->

<!-- begin::Demo Panel -->
<div
	ktOffcanvas
	[options]="demoPanelOptions"
	id="kt_demo_panel"
	class="kt-demo-panel kt-demo-panel--on"
	style="padding-left: 4px; padding-right: 4px; opacity: 1; z-index: 100; max-width: 170px !important; margin-top: 40px"
>
	<div class="kt-demo-panel__body kt-scroll kt-notification-v2">
		<div class="kt-demo-panel__head" style="border-bottom: 1px solid #f1f1f1; margin-bottom: 0px; padding-bottom: 25px">
			<h3 class="kt-demo-panel__title" style="padding-left: 29px; font-size: 27px; color: #c61e1e; font-weight: 700">
				Top 10
			</h3>
			<a href="javascript:;" class="kt-demo-panel__close" id="kt_demo_panel_close"><i class="flaticon2-delete"></i></a>
		</div>
		<a
			*ngFor="let ebat of topOnListesi"
			[routerLink]="['/performans-lastik-arama', ebat.txtebatkod]"
			class="kt-notification-v2__item"
		>
			<div class="kt-notification-v2__itek-wrapper">
				<div class="kt-notification-v2__item-title">
					{{ ebat.txtebatkod }}
				</div>
				<!-- <div class="kt-notification-v2__item-desc">
							225/55R16
						</div> -->
			</div>
		</a>
	</div>
</div>
<!-- end::Demo Panel -->
