export const environment = {
  firebase: {
    projectId: 'brisarandevumodulu',
    appId: '1:948876566819:web:bc916d878bcda0f8c06c96',
    storageBucket: 'brisarandevumodulu.appspot.com',
    apiKey: 'AIzaSyCFSlJ-dtv2U_z82qTVVZH8Lg-SJCt-h3E',
    authDomain: 'brisarandevumodulu.firebaseapp.com',
    messagingSenderId: '948876566819',
    measurementId: 'G-F4LVX0TMQT',
  },
	production: true,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5'
};
