import { Pipe, PipeTransform } from '@angular/core';
import { IlceListesiResponse } from '../../../../../core/randevu/hizmet-noktasi/models/ilce-listesi.response';

@Pipe({
	name: 'ilceSearch'
})
export class IlceSearchPipe implements PipeTransform {
	transform(value: IlceListesiResponse[], filterText?: string): IlceListesiResponse[] {
		filterText = filterText ? filterText.toLocaleLowerCase() : null;
		return filterText
			? value.filter((d: IlceListesiResponse) => d.txtIlceAdi.toLocaleLowerCase().indexOf(filterText) !== -1)
			: value;
	}
}
