import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { User, currentUser, Logout } from 'app/core/auth';
import { AppState } from 'app/core/reducers';
import { AuthService, LoginType } from 'app/core/auth/_services/auth.service';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<User>;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	// #region ADDED BY MIYA
	plaka = '';
	fullName = '';
	plakaGoster = false;
	kullaniciAdi = '';
	// #endregion ADDED BY MIYA

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>, private authService: AuthService) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.plaka = this.authService.CurrentUser().UserName;
		this.fullName = this.authService.CurrentUser().FullName;
		this.kullaniciAdi = this.authService.CurrentUser().UserName;

		if (localStorage.getItem('loginType') === 'plaka') {
			this.plakaGoster = true;
		} else {
			this.plakaGoster = false;
		}
		this.user$ = this.store.pipe(select(currentUser));
	}

	/**
	 * Log out
	 */
	logout() {
		localStorage.clear();
		this.store.dispatch(new Logout());
	}
}
