<div class="brisa-header-container">
	<span class="kt-header__topbar-welcome" *ngIf="plakaGoster" style="font-size: 20px; font-weight: 600">
		{{ plaka }}
	</span>

	<div class="row dist-adi" *ngIf="distadi != '' && router.url.split('/')[1] !== 'performans-lastik-arama'">
		<div style="display: flex; flex: 1">
			<span>{{ distadi }} </span>
			<div
				class="marquee"
				*ngIf="
					logintipi != 'filo' &&
					(router.url.split('/')[2] === 'yeni-saklama' ||
						router.url.split('/')[2] === 'saklama-listesi' ||
						router.url.split('/')[2] === 'filo-hizmet-islemleri')
				"
			>
				<p>
					1 Ocak 2024 tarihi itibariyle Operasyonel filo kiralama (OFK) şirketlerinin sökme- takma ve saklama prim
					ödemeleri Panorama – Saklama modülünde görünen lastiklere verilecektir.
				</p>
			</div>
			<!-- <div class="marquee">
				<p>
					1 Ocak 2024 tarihi itibariyle Operasyonel filo kiralama (OFK) şirketlerinin sökme- takma ve saklama prim
					ödemeleri Panorama – Saklama modülünde görünen lastiklere verilecektir.
				</p>
			</div> -->
		</div>
	</div>

	<div class="row performans-header" *ngIf="router.url.split('/')[1] === 'performans-lastik-arama'">
		<h3 class="col-5 slogan1">{{ slogan1 }}</h3>
		<div class="col-2 logo-div">
			<img class="brisa-image1" alt="logo" src="./assets/media/logos/lassaa.jpg" />
			<img class="brisa-image2" alt="logo" src="./assets/media/logos/bridgestone.jpg" />
		</div>

		<h3 class="col-5 slogan2">{{ slogan2 }}</h3>
	</div>
</div>
