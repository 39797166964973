import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../utils/loader.service';

@Component({
	selector: 'kt-loader-comp',
	templateUrl: './loader-comp.component.html',
	styleUrls: ['./loader-comp.component.scss']
})
export class LoaderCompComponent {
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	isLoading: Subject<boolean> = this.loaderService.isLoading;

	constructor(private loaderService: LoaderService) {}
}
