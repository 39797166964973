import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { KullaniciAyarlariRequest } from '../models/request/kullaniciayarlari/kullaniciayarlarirequest';
import { KullaniciAyarlariResponse } from '../models/response/kullaniciayarlari/kullaniciayarlariresponse';
import { KullanıcıyaBagliFiloListesiResponese } from '../models/response/kullaniciayarlari/kulanicibaglifilolistesi.response';
import { MusteriKrediLimitiGetirResponse } from '../models/response/kullaniciayarlari/musterikredilimitigetir.response';

import { KullaniciAyarGetirRequest } from '../models/request/kullaniciayarlari/kullaniciayargetir.request';
import { ConfigService } from '../../../core/config.service';

@Injectable({
	providedIn: 'root'
})
export class KullaniciAyarlariService {
	private accesstoken = localStorage.getItem('access_token');
	private token_type = localStorage.getItem('token_type');
	private authorization = this.token_type + ' ' + this.accesstoken;
	private requestOptions = new HttpHeaders({
		'Content-Type': 'application/json', //,// 'application/x-www-form-urlencoded',
		Authorization: this.authorization
	});
	private options = { headers: this.requestOptions };
	private musteriKrediLimitleri: BehaviorSubject<MusteriKrediLimitiGetirResponse>;
	public musteriKrediLimitleri$: Observable<MusteriKrediLimitiGetirResponse>;

	private filokullanicikodu: BehaviorSubject<string>;
	public filokullanicikodu$: Observable<string>;

	private kullaniciyaBagliFiloListesi: BehaviorSubject<KullanıcıyaBagliFiloListesiResponese[]>;
	public kullaniciyaBagliFiloListesi$: Observable<KullanıcıyaBagliFiloListesiResponese[]>;

	constructor(private configService: ConfigService, private readonly http: HttpClient) {
		this.musteriKrediLimitleri = new BehaviorSubject<MusteriKrediLimitiGetirResponse>(null);
		this.musteriKrediLimitleri$ = this.musteriKrediLimitleri.asObservable();
		this.filokullanicikodu = new BehaviorSubject<string>(null);
		this.filokullanicikodu$ = this.filokullanicikodu.asObservable();
		this.kullaniciyaBagliFiloListesi = new BehaviorSubject<KullanıcıyaBagliFiloListesiResponese[]>(null);
		this.kullaniciyaBagliFiloListesi$ = this.kullaniciyaBagliFiloListesi.asObservable();
	}

	getKullaniciAyarlari(txtFiloKod: string): Observable<KullaniciAyarlariResponse> {
		const params = new HttpParams().set('txtFiloKod', txtFiloKod);
		return this.http.get<KullaniciAyarlariResponse>(
			this.configService.config.apiUrl + 'api/OfkFilo/FiloKullaniciAyarGetir',
			{
				params: params,
				headers: this.requestOptions
			}
		);
	}

	postKullaniciAyarGuncelle(ayarlar: KullaniciAyarlariRequest): Observable<boolean> {
		return this.http.post<boolean>(
			this.configService.config.apiUrl + 'api/OfkFilo/FiloKullaniciAyarEkleGuncelle',
			ayarlar,
			this.options
		);
	}

	postKullaniciAyarlari(ayarlar: KullaniciAyarGetirRequest): Observable<KullaniciAyarlariResponse> {
		return this.http.post<KullaniciAyarlariResponse>(
			this.configService.config.apiUrl + 'api/OfkFilo/KullaniciyaBagliFiloListesi',
			ayarlar,
			this.options
		);
	}

	getKullaniciyaBagliFiloListesi() {
		this.http
			.get<KullanıcıyaBagliFiloListesiResponese[]>(
				this.configService.config.apiUrl + 'api/OfkFilo/KullaniciyaBagliFiloListesi',
				{}
			)
			.subscribe(data => this.kullaniciyaBagliFiloListesi.next(data));
	}
	getMusteriKrediLimitleriGetir(txtMusteriKod: string): void {
		const params = new HttpParams().set('txtMusteriKod', txtMusteriKod?.toString());

		this.http
			.get<MusteriKrediLimitiGetirResponse>(
				this.configService.config.apiUrl + 'api/SapServis/MusteriKrediLimitleriGetir',
				{
					params: params,
					headers: this.requestOptions
				}
			)
			.subscribe(data => this.musteriKrediLimitleri.next(data));
	}
}
