// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
// Auth
import { AuthNoticeService } from '../../../../core/auth';

import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import swal from 'sweetalert2';
import { ConfigService } from '../../../../core/config.service';
import { enuUserType, LoginV2Request } from 'app/core/auth/_models/login/loginV2.request';
import { AuthService } from 'app/core/auth/_services/auth.service';
import { MenuAsideService } from 'app/core/_base/layout';
import { LastikAramaService } from 'app/core/randevu/lastik-arama/services/lastik-arama.service';
import { takeUntil } from 'rxjs/operators';
import { PlakaSorgulaResponse } from 'app/core/randevu/lastik-arama/models/plaka-sorgula.response';
import { Paging, PlakaRandevuListesiRequest } from 'app/core/randevu/lastik-arama/models/plaka-randevu-listesi.request';
import { LoginService } from 'app/core/auth/_services';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: 'admin@demo.com',
	PASSWORD: 'demo'
};

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgbTabsetConfig]
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	public plakaForm: FormGroup;
	public loading = false;
	private isLoggedIn$: Observable<boolean>;
	private errors: any = [];
	public today: number = Date.now();
	// #region ADDED BY MİYA
	public exampleTabset: {
		beforeCodeTitle: string;
		viewCode: string;
		isCodeVisible: boolean;
		isExampleExpanded: boolean;
	};
	private randevuListesiRequest: PlakaRandevuListesiRequest = new PlakaRandevuListesiRequest();

	private tabset = {
		beforeCodeTitle: 'Tabset',
		viewCode: ``,
		isCodeVisible: false,
		isExampleExpanded: true
	};
	// #endregion ADDED BY MİYA

	private unsubscribe: Subject<any>;
	private authVerision: number;
	private returnUrl: any;
	subDomainliDist: any;
	private loginType: string;
	private plaka: string;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */

	constructor(
		private lastikAramaService: LastikAramaService,
		private loginService: LoginService,
		private router: Router,
		private authNoticeService: AuthNoticeService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		public snackBar: MatSnackBar,
		private route: ActivatedRoute,
		private configService: ConfigService,
		private authService: AuthService,
		private menuService: MenuAsideService
	) {
		this.unsubscribe = new Subject();
		this.authVerision = configService.config.authVersion;
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	public girisParam;
	public imageBase64;
	ngOnInit(): void {
		const link = window.location.href;
		localStorage.removeItem('sipNo');

		this.route.params.subscribe(f => {
			if (f) {
				this.girisParam = f;
				if (this.girisParam.bayiad !== null && this.girisParam.bayiad !== undefined) {
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					this.lastikAramaService.getSubDomain(this.girisParam.bayiad).subscribe(f => {});
					this.loginService.getLogoFind(this.girisParam.bayiad).subscribe(f => {
						this.imageBase64 = f;
					});
				}
			}
		});

		if (localStorage.getItem('reloaded') === 'false' || localStorage.getItem('reloaded') === null) {
			localStorage.setItem('reloaded', 'true');
			location.reload();
		}

		this.exampleTabset = this.tabset;

		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	plakaInput(e): void {
		if (e.data === ' ') {
			const plaka: string = this.plakaForm.controls.plaka.value;
			this.plakaForm.controls.plaka.setValue(plaka.trim());
		}
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm(): void {
		// demo message to show
		if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			const initialNotice = `Use account
			<strong>${DEMO_PARAMS.EMAIL}</strong> and password
			<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
			this.authNoticeService.setNotice(initialNotice, 'info');
		}

		this.plakaForm = this.fb.group({
			plaka: [
				'',
				Validators.compose([
					Validators.required,
					Validators.minLength(5),
					Validators.maxLength(9),
					Validators.pattern('^[a-zA-Z0-9_]*$')
				])
			]
		});
	}

	/**
	 * Form Submit
	 */

	plakaSubmit(): void {
		const controls = this.plakaForm.controls;
		/** check form */
		if (this.plakaForm.invalid) {
			Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
			return;
		}

		this.loading = true;

		const authDatav2: LoginV2Request = {
			UserName: controls.plaka.value,
			Password: null,
			UserType: enuUserType.Plaka
		};

		this.authService.login(authDatav2).subscribe(
			() => {
				this.menuService.getMenu().subscribe(() => {
					this.loginType = localStorage.getItem('loginType');
					if (this.loginType === 'plaka') {
						this.plaka = this.authService.CurrentUser().UserName;
					}
					this.randevuListesiRequest.txtPlaka = this.plaka;
					this.randevuListesiRequest.paging = new Paging();
					this.randevuListesiRequest.paging.sayfa = 1;
					this.lastikAramaService
						.postPlakaRandevuListesi(this.randevuListesiRequest)
						.pipe(takeUntil(this.unsubscribe))
						.subscribe(data => {
							if (data.data.length === 0) {
								if (this.girisParam.bayiad !== undefined && this.girisParam.bayiad !== null) {
									//sublı giriş
									this.lastikAramaService
										.getSubDomain(this.girisParam.bayiad)
										.pipe(takeUntil(this.unsubscribe))
										.subscribe(response => {
											this.subDomainliDist = response.lngDistKod;
											if (response.bytTip === 1 && response.lngDistKod !== null) {
												const kayit: PlakaSorgulaResponse = {
													lngKod: 0,
													lngKayitYeri: 0,
													lngDistKod: this.subDomainliDist,
													subdanGeldi: true,
													lngMiktar: 0,
													txtErpKod: '',
													txtKayitYeri: '',
													txtPlaka: '',
													txtUnvan: '',
													txtAracKullanici: '',
													txtCepTelefonu: '',
													trhIslemTarihi: '',
													lngSaklamaHizmeti: 0,
													lngSokmeHizmeti: 0,
													txtAciklama: '',
													lngDepoDizilim: 0,
													saklamaDetayList: [],
													txtHizmetVerenEmail: '',
													txtHizmetVerenTel: ''
												};
												localStorage.setItem('secilenKayit', JSON.stringify(kayit));
												this.menuService.navigate('hizmet-noktasi-secimi');

												return;
											} else if (response.bytTip === 2 && response.lngDistKod === null) {
												this.authService.kullaniciTipi(true);
												// tip=2 ve dist kodu null döner ise
												this.router.navigateByUrl(`lastik-arama/${this.girisParam.bayiad}`);

												//istek atılacak ve bayi list alınacak
											}
										});
								} else {
									const sipNo = this.girisParam.sipNo;
									if (sipNo !== null && sipNo !== undefined) {
										this.authService.postTrendyolRandevuOku(sipNo).subscribe(f => {
											if (f.content[0].status !== null && f.content[0].status !== undefined) {
												localStorage.setItem('sipNo', sipNo.toString());
												this.menuService.navigate(`lastik-arama`);
											} else {
												swal.fire('Hatalı Sipariş Numarası');
												return;
											}
										});
									} else {
										this.menuService.navigate(`lastik-arama:${this.girisParam}`);
									}
								}
							} else {
								this.menuService.navigate(`lastik-arama:${this.girisParam}`);
							}
						});
				});
			},
			(err: HttpErrorResponse) => {
				this.loading = false;
				if (err.status === 401) {
					swal.fire('Hata', 'Belirtilen plaka sistemde bulunamadı.', 'error');
				} else {
					swal.fire('Hata', err.error.ErrorMessage, 'error');
				}
				this.cdr.markForCheck();
			}
		);
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isPlakaControlHasError(controlName: string, validationType: string): boolean {
		const control = this.plakaForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
