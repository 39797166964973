// Angular
import { Component } from '@angular/core';
// Layout
import { LayoutConfigService, OffcanvasOptions } from '../../../../core/_base/layout';
import { PerformansLastikAramaService } from '../../../../core/performans-lastik/performans-lastik-arama/services/performans-lastik-arama.service';
import { TopOnListesiResponse } from '../../../../core/performans-lastik/performans-lastik-arama/models/topOnListesiResponse';

@Component({
	selector: 'kt-sticky-toolbar',
	templateUrl: './sticky-toolbar.component.html',
	styleUrls: ['./sticky-toolbar.component.scss']
})
export class StickyToolbarComponent {
	// Public properties
	demoPanelOptions: OffcanvasOptions = {
		overlay: true,
		baseClass: 'kt-demo-panel',
		closeBy: 'kt_demo_panel_close',
		toggleBy: 'kt_demo_panel_toggle'
	};

	topOnListesi: TopOnListesiResponse[] = [];
	constructor(private topOnSevice: PerformansLastikAramaService) {}
	ngOnInit() {
		this.topOnSevice.getTopOnListesi().subscribe(data => {
			this.topOnListesi = data;
		});
	}
}
