import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutConfigService, ToggleOptions } from '../../../../core/_base/layout';

@Component({
	selector: 'kt-header-mobile',
	templateUrl: './header-mobile.component.html',
	styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {
	headerLogo: string;
	asideDisplay: boolean;

	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-header__topbar--mobile-on',
		togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
	};

	constructor(private layoutConfigService: LayoutConfigService, public router: Router) {}

	ngOnInit(): void {
		this.headerLogo = this.layoutConfigService.getStickyLogo();
		this.asideDisplay = this.layoutConfigService.getConfig('aside.self.display');
	}
}
