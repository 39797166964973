<!--begin::Head-->
<div class="kt-login__head">
	<a
		routerLink="/auth/login"
		id="kt_plaka_randevu_submit"
		class="btn btn-light"
		style="padding-top: 15px; padding-bottom: 15px; font-family: Roboto, 'Helvetica Neue', sans-serif"
		>Plaka ile Randevu Girişi</a
	>
</div>
<!--end::Head-->

<!--begin::Body-->
<div class="kt-login__body">
	<!--begin::Signin-->
	<div class="kt-login__form panel p-4" style="max-width: 300px">
		<a href="javascript:;" class="kt-login__logo">
			<img src="./assets/media/logos/brisa-logo.png" style="width: 100%; margin-top: 0px" alt="" />
		</a>

		<div class="kt-login__title">
			<h3>
				Lastik Bulutu Portalına <br />
				Hoş Geldiniz
			</h3>
		</div>

		<!-- <kt-auth-notice></kt-auth-notice> -->

		<kt-material-preview>
			<div class="kt-section">
				<div class="kt-section__content">
					<form class="kt-form" [formGroup]="hizmetNoktasiForm" autocomplete="off">
						<div class="form-group">
							<mat-form-field appearance="outline">
								<mat-label>Kullanıcı Adı</mat-label>
								<input
									matInput
									type="text"
									placeholder="Kullanıcı Adı"
									formControlName="kullaniciAdi"
									autocomplete="off"
								/>
								<mat-error *ngIf="isHizmetNoktasiControlHasError('kullaniciAdi', 'required')">
									<strong>{{ 'Alan boş geçilemez' | translate }}</strong>
								</mat-error>
							</mat-form-field>
						</div>
						<div class="form-group">
							<mat-form-field appearance="outline">
								<mat-label>Şifre</mat-label>
								<input matInput type="password" placeholder="Şifre" formControlName="sifre" autocomplete="off" />
								<mat-error *ngIf="isHizmetNoktasiControlHasError('sifre', 'required')">
									<strong>{{ 'Alan boş geçilemez' | translate }}</strong>
								</mat-error>
							</mat-form-field>
						</div>

						<div class="row">
							<div class="col-12">
								<div class="kt-login__actions" style="display: block !important; text-align: right">
									<a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link kt-login__link-forgot"> </a>
									<button
										(click)="sorumluGirisiSubmit()"
										id="kt_sorumlu_girisi_submit"
										class="btn btn-primary btn-elevate kt-login__btn-primary btnbtn"
										style="width: 100%; font-size: 18px; font-family: Roboto, 'Helvetica Neue', sans-serif"
									>
										Giriş
									</button>
								</div>
							</div>

							<div class="col-12" *ngIf="false">
								<div class="kt-login__actions" style="display: block !important; text-align: left">
									<a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link kt-login__link-forgot"> </a>
									<button
										(click)="kayitol()"
										style="width: 100%; font-size: 18px; font-family: Roboto, 'Helvetica Neue', sans-serif"
										id="kt_kayit_ol_submit"
										class="btn btn-default btn-elevate kt-login__btn-primary btnbtn"
									>
										Kayıt Ol
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</kt-material-preview>

		<div class="kt-grid__item">
			<div class="kt-login__info">
				<div class="kt-login__copyright sorumluu">© {{ today | date : 'yyyy' }} Brisa Bridgestone Sabancı</div>
			</div>
		</div>

		<!-- <kt-auth-notice></kt-auth-notice> -->
	</div>
	<!--end::Signin-->
</div>
<!--end::Body-->
