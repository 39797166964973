// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Auth
import { AuthGuard } from './core/auth';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ErrorComponent } from './core/auth/error/error/error.component';
import { ArventoerrComponent } from './core/auth/error/arvento-error/arventoerr/arventoerr.component';

const routes: Routes = [
	{ path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },

	{
		path: 'otopratik/:plaka',
		loadChildren: () => import('./views/pages/otopratik/otopratik.module').then(m => m.OtopratikModule)
	},
	{
		path: 'propratik/:plaka',
		loadChildren: () => import('./views/pages/otopratik/otopratik.module').then(m => m.OtopratikModule)
	},

	{
		path: 'filo-randevu-girisi',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/filo-randevu-girisi/filo-randevu-girisi.module').then(
						m => m.FiloRandevuGirisiModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'performans-lastik-parametreleri',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import(
						'./views/pages/performans-lastik/performans-lastik-parametreleri/performans-lastik-parametreleri.module'
					).then(m => m.PerformansLastikParametreleriModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'dashboard',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'randevu/:plaka',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/lastik-arama/lastik-arama.module').then(m => m.LastikAramaModule)
				// canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'lastik-arama/:bayiad',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/lastik-arama/lastik-arama.module').then(m => m.LastikAramaModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'lastik-arama',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/lastik-arama/lastik-arama.module').then(m => m.LastikAramaModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'takvim',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./views/pages/randevu/takvim/takvim.module').then(m => m.TakvimModule),
				canActivate: [AuthGuard]
			}
		]
	},

	{
		path: 'bayi-takvimi',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/bayi-takvimi/bayi-takvimi.module').then(m => m.BayiTakvimiModule),
				canActivate: [AuthGuard]
			}
		]
	},

	{
		path: 'hizmet-gunluk-randevu',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/hizmet-noktasi-gunluk-randevu/hizmet-noktasi-gunluk-randevu.module').then(
						m => m.HizmetNoktasiGunlukRandevuModule
					),
				canActivate: [AuthGuard]
			}
		]
	},

	{
		path: 'hizmet-noktasi-secimi',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/hizmet-noktasi-secimi/hizmet-noktasi-secimi.module').then(
						m => m.HizmetNoktasiSecimiModule
					),
				canActivate: [AuthGuard]
			}
		]
	},

	{
		path: 'mesai-secimi',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/mesai-secimi/mesai-secimi.module').then(m => m.MesaiSecimiModule),
				canActivate: [AuthGuard]
			}
		]
	},

	{
		path: 'saat-secimi/:id',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/saat-secimi/saat-secimi.module').then(m => m.SaatSecimiModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'lokasyon-depo-esleme',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/lokasyon-depo-esleme/lokasyon-depo-esleme.module').then(
						m => m.LokasyonDepoEslemeModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'hizmet-noktasi-ekleme',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/hizmet-noktasi-ekleme/hizmet-noktasi-ekleme.module').then(
						m => m.HizmetNoktasiEklemeModule
					),
				canActivate: [AuthGuard]
			}
		]
	},

	{
		path: 'hizmet-noktasi-tanimlama',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/lokasyon-ekleme/lokasyon-ekleme.module').then(m => m.LokasyonEklemeModule),
				canActivate: [AuthGuard]
			}
		]
	},

	{
		path: 'gun-ve-saat-kapatma',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/gun-saat-kapatma/gun-saat-kapatma.module').then(m => m.GunSaatKapatmaModule),
				canActivate: [AuthGuard]
			}
		]
	},
	///
	{
		path: 'filo-takvimi',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/filo-takvimi/filo-takvimi.module').then(m => m.FiloTakvimiModule),
				canActivate: [AuthGuard]
			}
		]
	},

	{
		path: 'filo-gunluk-randevu',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/randevu/filo-gunluk-randevu/filo-gunluk-randevu.module').then(
						m => m.FiloGunlukRandevuModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'filo-excel-upload',
		component: BaseComponent,
		children: [
			{
				path: 'filo-excel-upload',
				loadChildren: () =>
					import('./views/pages/randevu/filo-excel-upload/filo-excel-upload.module').then(m => m.FiloExcelUploadModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'arvento-saklama',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/lastik-saklama/lastik-saklama.module').then(m => m.LastikSaklamaModule)
			}
		]
	},
	{
		path: 'saklama',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/lastik-saklama/lastik-saklama.module').then(m => m.LastikSaklamaModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'performans-lastik-arama',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/performans-lastik/performans-lastik-arama/performans-lastik-arama.module').then(
						m => m.PerformansLastikAramaModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'performans-lastik-arama/:id',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/performans-lastik/performans-lastik-arama/performans-lastik-arama.module').then(
						m => m.PerformansLastikAramaModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'siparis-girisi',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofkas/siparis-girisi/siparis-girisi.module').then(m => m.SiparisGirisiModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'filo-sozlesmeli-secim',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/filo/filo-sozlesmeli-secim/filo-sozlesmeli-secim/filo-sozlesmeli-secim.module').then(
						m => m.FiloSozlesmeliSecimModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'siparislerim',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofkas/siparislerim/siparislerim.module').then(m => m.SiparislerimModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'siparis-dashboard',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/siparis-dashboard/siparis-dashboard.module').then(m => m.SiparisDashboardModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'taslaklarim',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofkas/taslaklarim2/taslaklarim2.module').then(m => m.Taslaklarim2Module),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'excel-yukle',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./views/pages/ofkas/excel-yukle/excel-yukle.module').then(m => m.ExcelYukleModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'excel-aktarim-izleme',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofkas/exel-aktarim-izleme/exel-aktarim-izleme.module').then(
						m => m.ExelAktarimIzlemeModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'kullanici-ayarlari',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofkas/kullanici-ayarlari/kullanici-ayarlari.module').then(
						m => m.KullaniciAyarlariModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'bayi-onay-islemleri',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofk-panorama/bayi-onay-islemleri/bayi-onay-islemleri.module').then(
						m => m.BayiOnayIslemleriModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'bayi-lokasyon-girisi',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofk-panorama/bayi-lokasyon-girisi/bayi-lokasyon-girisi.module').then(
						m => m.BayiLokasyonGirisiModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'bayi-yonetim-islemleri',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofk-panorama/bayi-yonetim-islemleri/bayi-yonetim-islemleri.module').then(
						m => m.BayiYonetimIslemleriModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'performans-lastik-il-secim',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import(
						'./views/pages/performans-lastik/performans-lastik-il-secimi/performans-lastik-il-secim/performans-lastik-il-secim.module'
					).then(m => m.PerformansLastikIlSecimModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'bayi-sozlesme-girisi',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofk-panorama/bayi-sozlesme-girisi/bayi-sozlesme-girisi.module').then(
						m => m.BayiSozlesmeGirisiModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'filo',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./views/pages/filo/filo.module').then(m => m.FiloModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'sepetim',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofkas/siparis-girisi/components/sepetim/sepetim.module').then(m => m.SepetimModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'siparis-girisi-kriter-tanimlama',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/ofkas/siparis-girisi-kriter-tanimlama/siparis-girisi-kriter-tanimlama.module').then(
						m => m.SiparisGirisiKriterTanimlamaModule
					),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'genel-parametreler',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./views/pages/genel-parametreler/genel-parametreler.module').then(m => m.GenelParametrelerModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'bayi-yonetim',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./views/pages/bayi-yonetim/bayi-yonetim.module').then(m => m.BayiYonetimModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'yetki-modulu',
		component: BaseComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./views/pages/yetki-modulu/yetki-modulu.module').then(m => m.YetkiModule),
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'error',
		component: ErrorComponent
	},
	{
		path: 'arventoerror',
		component: ArventoerrComponent
	},

	{ path: ':bayiad', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
	// { path: '', redirectTo: 'auth', pathMatch: 'full' },
	{ path: '**', redirectTo: 'error/403', pathMatch: 'full' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
	exports: [RouterModule]
})
export class AppRoutingModule {}
