import { Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HizmetOneriBayiListesiResponse } from 'app/core/randevu/hizmet-noktasi/models/hizmet-oneri-bayi-listesi.response';

@Pipe({
	name: 'hoblTxtIlcePipe'
})
export class hoblTxtIlcePipe implements PipeTransform {
	transform(value: MatTableDataSource<HizmetOneriBayiListesiResponse>, filtre: string): any {
		filtre = filtre ? filtre.toString().toLocaleLowerCase() : null;
		let a = filtre
			? value.data.filter(
					(c: HizmetOneriBayiListesiResponse) => c.txtIlceAd.toString().toLocaleLowerCase().indexOf(filtre) !== -1
			  )
			: value;
		return a;
	}
}

@Pipe({
	name: 'hoblTxtLokasyonAdPipe'
})
export class hoblTxtLokasyonAdPipe implements PipeTransform {
	transform(value: MatTableDataSource<HizmetOneriBayiListesiResponse>, filtre: string): any {
		filtre = filtre ? filtre.toString().toLocaleLowerCase() : null;
		let a = filtre
			? value.data.filter(
					(c: HizmetOneriBayiListesiResponse) => c.txtLokasyonAd.toString().toLocaleLowerCase().indexOf(filtre) !== -1
			  )
			: value;
		return a;
	}
}

@Pipe({
	name: 'hoblTxtBayiAdPipe'
})
export class hoblTxtBayiAdPipe implements PipeTransform {
	transform(value: MatTableDataSource<HizmetOneriBayiListesiResponse>, filtre: string): any {
		filtre = filtre ? filtre.toString().toLocaleLowerCase() : null;
		let returned = filtre
			? value.data.filter(
					(c: HizmetOneriBayiListesiResponse) => c.txtLokasyonAd.toString().toLocaleLowerCase().indexOf(filtre) !== -1
			  )
			: value;
		return returned;
	}
}
