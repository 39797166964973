<!-- begin: Header -->
<div
	ktHeader
	#ktHeader
	class="kt-header kt-grid__item"
	[ngClass]="htmlClassService.getClasses('header', true)"
	id="kt_header"
	style="z-index: 101 !important"
>
	<ngb-progressbar
		class="kt-loading-bar"
		*ngIf="(loader.progress$ | async) > 0"
		[value]="loader.progress$ | async"
		height="3px"
	></ngb-progressbar>

	<!-- begin: Header Menu -->
	<kt-menu-horizontal *ngIf="menuHeaderDisplay"></kt-menu-horizontal>
	<!-- end: Header Menu -->

	<!-- begin:: Header Topbar -->
	<!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
	<div *ngIf="!menuHeaderDisplay"></div>
	<kt-topbar></kt-topbar>
	<div class="hidden-md hidden-xl hidden-lg hidden-sm col-xs-12" style="background: #fff; margin-bottom: 8px">
		<!-- *ngIf="user$ | async as _user" -->
		<!-- ngbDropdownToggle -->
		<div class="kt-header__topbar-user" [ngClass]="{ 'kt-header__topbar-icon': icon }" style="text-align: center">
			<brisa-header id="hea" [distadi]="distadi" [plakaGoster]="plakaGoster"></brisa-header>

			<i *ngIf="icon" class="flaticon2-user-outline-symbol"></i>
			<img class="hidden-xs" *ngIf="avatar" src="../../../../../../assets/media/users/default.jpg" alt="Pic" />
			<!-- [attr.src]="_user.pic" -->
			<span
				class="hidden-xs"
				*ngIf="badge"
				class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
			>
				<!-- {{_user.fullname|firstLetter}} -->
			</span>
		</div>

		<kt-filo-selectbox></kt-filo-selectbox>
	</div>
	<!-- end:: Header Topbar -->
</div>
<!-- end: Header -->
