import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	config: Config;
	constructor(private http: HttpClient) {}

	async loadConfig(): Promise<void> {
		const config = await this.http.get<Config>('./assets/config.json').toPromise();
		if (config.apiUrl.slice(-1) !== '/') config.apiUrl += '/';
		this.config = config;
	}
}

export class Config {
	apiUrl: string;
	production: boolean;
	version: string;
	authVersion: number;
}

export const configFactory = (configService: ConfigService) => {
	return (): Promise<void> => configService.loadConfig();
};
