// Angular
import { Injectable } from '@angular/core';
// RxJS
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'app/core/config.service';
import { SloganResponse } from '../models/sloganResponse';

@Injectable()
export class MenuConfigService {
	// Public properties
	onConfigUpdated$: Subject<any>;
	// Private properties
	private menuConfig: any;

	/**
	 * Service Constructor
	 */
	constructor(private http: HttpClient, private configService: ConfigService) {
		// register on config changed event and set default config
		this.onConfigUpdated$ = new Subject();
	}
	private requestOptions = new HttpHeaders({
		'Content-Type': 'application/json',
		Authorization: 'bearer' + ' ' + localStorage.getItem('accessToken'),
		Accept: 'application/json'
	});
	private options = { headers: this.requestOptions };

	getMenus(): any {
		return this.menuConfig;
	}

	/**
	 * Load config
	 *
	 * @param config: any
	 */
	loadConfigs(config: any): void {
		this.menuConfig = config;
		this.onConfigUpdated$.next(this.menuConfig);
	}
	getSlogan(): Observable<SloganResponse> {
		return this.http.get<SloganResponse>(this.configService.config.apiUrl + 'api/Bildirim/Slogan', this.options);
	}
}
