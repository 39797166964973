import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PlakaSorgulaResponse } from '../models/plaka-sorgula.response';
import { PlakaRandevuListesiRequest } from '../models/plaka-randevu-listesi.request';
import { PlakaRandevuListesiResponse } from '../models/plaka-randevu-listesi.response';
import { FiloPlakaSorgulaResponse } from '../models/filo-plaka-sorgula.response';
import { ConfigService } from '../../../../core/config.service';
import { SmsGonderRequest } from 'app/core/filo/models/request/filo-islem/sms-gonderrequest';
import { SmsGonderResponse } from 'app/core/filo/models/response/filo-islem/sms-gonderesponse';
import { CheckOnayRequest } from 'app/core/filo/models/request/filo-islem/checkOnayrequet';
import { checkOnayResponse, SubDomainResponse } from 'app/core/filo/models/response/filo-islem/checkOnayResponse';
import { bayiListesiResponse } from 'app/views/pages/randevu/bayi-secim-modal/models/bayi-listesi.response';

@Injectable({
	providedIn: 'root'
})
export class LastikAramaService {
	constructor(private http: HttpClient, private configService: ConfigService) {}

	getPlakaSorgula(txtPlaka: string): Observable<PlakaSorgulaResponse[]> {
		const params = new HttpParams().set('txtPlaka', txtPlaka);
		return this.http.get<PlakaSorgulaResponse[]>(this.configService.config.apiUrl + 'api/Saklama/PlakaSorgula', {
			params: params
		});
	}

	getFiloPlakaSorgula(txtPlaka: string, txtErpKod: string): Observable<FiloPlakaSorgulaResponse[]> {
		const params = new HttpParams().set('txtPlaka', txtPlaka).set('txtErpKod', txtErpKod);
		return this.http.get<FiloPlakaSorgulaResponse[]>(
			this.configService.config.apiUrl + 'api/Saklama/FiloPlakaSorgula',
			{ params: params }
		);
	}

	postPlakaRandevuListesi(req: PlakaRandevuListesiRequest): Observable<PlakaRandevuListesiResponse> {
		return this.http.post<PlakaRandevuListesiResponse>(
			this.configService.config.apiUrl + 'api/Randevu/PlakaRandevuListesi',
			req
		);
	}
	postsmsGonder(req: SmsGonderRequest): Observable<SmsGonderResponse> {
		return this.http.post<SmsGonderResponse>('api/FiloHizmetTanimlar/SendOnaySms', req);
	}
	postcheckOnay(req: CheckOnayRequest): Observable<checkOnayResponse> {
		return this.http.post<checkOnayResponse>('api/FiloHizmetTanimlar/CheckOnay', req);
	}

	postPlakaRandevuIptal(lngKod: number): Observable<boolean> {
		return this.http.get<boolean>(
			`${this.configService.config.apiUrl}api/Randevu/PlakaGirisiVeFiloYoneticiRandevuIptal/${lngKod}`
		);
	}
	getSubDomain(request: string): Observable<SubDomainResponse> {
		const params = new HttpParams().set('request', request);
		const donus = this.http.get<SubDomainResponse>(this.configService.config.apiUrl + 'api/SubDomain/SubDomainGetir', {
			params: params
		});
		donus.subscribe(f => {
			console.log(f);
			localStorage.setItem('unvan', f.txtUnvan.toString());
			localStorage.setItem('subDomainParametreler', JSON.stringify(f));
		});
		return donus;
	}
	getDomainKisitliBayiGetir(url: string): Observable<bayiListesiResponse[]> {
		const params = new HttpParams().set('url', url);
		return this.http.get<bayiListesiResponse[]>(
			this.configService.config.apiUrl + 'api/Saklama/DomainKisitliBayiGetir',
			{
				params: params
			}
		);
	}
}
