import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { notificationRequest } from './model/NotificationRequest';
import { ConfigService } from '../config.service';
@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	constructor(private http: HttpClient, private configService: ConfigService) {}
	NotificationKaydet(req: notificationRequest): Observable<boolean> {
		return this.http.post<boolean>(this.configService.config.apiUrl + 'api/Entegrasyon/NotificationKaydet', req, {});
	}
}
