import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BayilokasyondialogComponent } from '../bayilokasyondialog/bayilokasyondialog.component';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
	selector: 'kt-arventoerr',
	templateUrl: './arventoerr.component.html',
	styleUrls: ['./arventoerr.component.scss']
})
export class ArventoerrComponent implements OnInit {
	public res: string;
	public imageSrc: string;

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	constructor(private dialog: MatDialog) {}
	ngOnInit(): void {
		this.imageSrc = '/assets/media/snow.png';
		// snowaysround.png
		//SnowaysRou.png
		//snoww.png

		this.res = '/assets/BRİSA_Lastik-Otel.jpg';
	}
	lokasyonAc() {
		const dialogRef = this.dialog.open(BayilokasyondialogComponent, {
			width: '85vw',
			maxWidth: '85vw',
			height: '90%',
			panelClass: 'mat-dialog-bg'
		});
	}
}
