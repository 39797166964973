import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LokasyonTanimlamaRequest, Telefon } from '../models/lokasyon-tanimlama.request';
import { Observable } from 'rxjs';
import { LokasyonGuncellemeResponse } from '../models/lokasyon-guncelleme.response';
import { BayiLokasyonListesiResponse, BayiLokasyonListesiResponseTcp } from '../models/bayi-lokasyon-listesi.response';
import { BayiDepoListesi, BayiDepoListesiTcp } from '../models/bayi-depo-listesi.response';
import { LokasyonKullaniciListesiResponse } from '../models/lokasyon-kullanici-listesi.response';
import { TarihAralikRandevuSaatRequest } from '../models/tarih-aralik-randevu-saat-request';
import { IlListesiResponse } from '../models/il-listesi.response';
import { IlceListesiResponse } from '../models/ilce-listesi.response';
import { ConfigService } from 'app/core/config.service';
import { TarihAralikRandevuSaatResponse } from '../models/tarih-aralik-randevu-saat-response';

import { TeslimatNoktasiListesiResponse } from '../models/teslimat-noktasi-listesi.response';
import { TeslimatTanimlamaRequest } from '../models/teslimat-tanimlama.request';
import { HizmetNoktasiIletisimRequest } from '../models/hizmet-noktasi-iletisim-request';
import { HizmetNoktasiTelefonSecenekModel } from '../models/hizmet-noktasi-tel-secenek';
import { LokasyonTelResponse } from '../models/lokasyon-tel-getir.response';
import { BayiLokasyonIletisimParametreleriResponse } from '../models/bayi-lokasyon-iletisim-parametreleri.response';
import { HizmetOneriBayiListesiRequest } from '../models/hizmet-oneri-bayi-listesi.request';
import { HizmetOneriBayiListesiResponse } from '../models/hizmet-oneri-bayi-listesi.response';
import { BayiKonumuResponse } from '../models/bayi-konumu.response';

@Injectable({
	providedIn: 'root'
})
export class LokasyonService {
	constructor(private http: HttpClient, private configService: ConfigService) {}

	getIlListesi(): Observable<IlListesiResponse[]> {
		return this.http.get<IlListesiResponse[]>(this.configService.config.apiUrl + 'api/Parametre/IlListesiGetir');
	}

	getIlceListesi(lngIlKod: number): Observable<IlceListesiResponse[]> {
		const params = new HttpParams().set('lngIlKod', lngIlKod.toString());
		return this.http.get<IlceListesiResponse[]>(this.configService.config.apiUrl + 'api/Parametre/IlceListesiGetir', {
			params: params
		});
	}

	postHizmetNoktaTanimlama(req: LokasyonTanimlamaRequest): Observable<number> {
		return this.http.post<number>(this.configService.config.apiUrl + 'api/Parametre/HizmetNoktaTanimlama', req);
	}
	postTeslimatNoktasiTanimlama(req: TeslimatTanimlamaRequest): Observable<number> {
		return this.http.post<number>(this.configService.config.apiUrl + 'api/Parametre/TeslimatNoktasiTanimlama', req);
	}

	postHizmetNoktaRandevuSaatleriSorgulama(
		req: TarihAralikRandevuSaatRequest
	): Observable<TarihAralikRandevuSaatResponse[]> {
		return this.http.post<TarihAralikRandevuSaatResponse[]>(
			this.configService.config.apiUrl + 'api/Randevu/HizmetNoktaTarihAralikRandevuSaatler',
			req
		);
	}
	postDoluRandevular(req: TarihAralikRandevuSaatRequest): Observable<TarihAralikRandevuSaatResponse[]> {
		return this.http.post<TarihAralikRandevuSaatResponse[]>(
			this.configService.config.apiUrl + 'api/Randevu/DoluRandevular',
			req
		);
	}

	postHizmetNoktaGuncelleme(req: LokasyonTanimlamaRequest): Observable<LokasyonGuncellemeResponse> {
		return this.http.post<LokasyonGuncellemeResponse>(
			this.configService.config.apiUrl + 'api/Parametre/HizmetNoktaTanimGuncelleme',
			req
		);
	}
	postTeslimatNoktaGuncelleme(req: TeslimatTanimlamaRequest): Observable<LokasyonGuncellemeResponse> {
		return this.http.post<LokasyonGuncellemeResponse>(
			this.configService.config.apiUrl + 'api/Parametre/TeslimatNoktaTanimGuncelleme',
			req
		);
	}

	getBayiLokasyonListesi(): Observable<BayiLokasyonListesiResponseTcp[]> {
		return this.http.get<BayiLokasyonListesiResponseTcp[]>(
			this.configService.config.apiUrl + 'api/Parametre/BayiLokasyonListesi'
		);
	}
	RandevuBayiLokasyonList(distkodlar?: number[]): Observable<BayiLokasyonListesiResponseTcp[]> {
		const distkodlarString = distkodlar.join(',');
		const params = new HttpParams().set('lngDistKod', distkodlarString);

		return this.http.get<BayiLokasyonListesiResponseTcp[]>(
			this.configService.config.apiUrl + 'api/Parametre/RandevuBayiLokasyonList',
			{
				params: params
			}
		);
	}
	getHizmetOneriBayiListesi(req: HizmetOneriBayiListesiRequest): Observable<HizmetOneriBayiListesiResponse[]> {
		return this.http.post<HizmetOneriBayiListesiResponse[]>(
			this.configService.config.apiUrl + 'api/Parametre/HizmetOneriBayiListesi',
			req
		);
	}
	getBayiLokasyonIletisimParametreleri(): Observable<BayiLokasyonIletisimParametreleriResponse[]> {
		return this.http.get<BayiLokasyonIletisimParametreleriResponse[]>(
			'api/Parametre/BayiLokasyonIletisimParametreleri'
		);
	}
	getBayiDepoListesi(lngDistKod: number): Observable<BayiDepoListesiTcp[]> {
		const params = new HttpParams().set('lngDistKod', lngDistKod.toString());
		return this.http.get<BayiDepoListesiTcp[]>(
			this.configService.config.apiUrl + 'api/Parametre/BayiPanoramaDepoListe',
			{
				params: params
			}
		);
	}

	bayiKonumListesi(txtKod: string): Observable<BayiKonumuResponse> {
		const params = new HttpParams().set('txtKod', txtKod.toString());

		return this.http.get<BayiKonumuResponse>(this.configService.config.apiUrl + 'api/Parametre/BayiKonumListesi', {
			params: params
		});
	}

	getLokasyonKullaniciListesi(lngDistKod: number): Observable<LokasyonKullaniciListesiResponse[]> {
		const params = new HttpParams().set('lngDistKod', lngDistKod.toString());
		return this.http.get<LokasyonKullaniciListesiResponse[]>(
			this.configService.config.apiUrl + 'api/Parametre/DistPanoramaKullaniciListesi',
			{
				params: params
			}
		);
	}

	telefonKontrol(telefon: string, telefonlar: Telefon[]) {
		const tel = Number(telefon);
		if (!isNaN(tel)) {
			if (telefonlar.find(x => Number(x.numara) === tel)) {
				return -1;
			}
			return tel.toString();
		}
		return null;
	}

	arrayToStringTelefon(telefonlar: Telefon[]): string {
		const telefonArray = [];
		telefonlar.forEach(x => {
			telefonArray.push(x.numara);
		});
		return telefonArray.toString();
	}

	stringToArray(telefonlar: string): Telefon[] {
		const telefonArray: Telefon[] = [];
		telefonlar.split('/').forEach(x => {
			telefonArray.push({ numara: x });
		});
		return telefonArray;
	}
	getTeslimatNoktasiListesi(): Observable<TeslimatNoktasiListesiResponse[]> {
		return this.http.get<TeslimatNoktasiListesiResponse[]>(
			this.configService.config.apiUrl + 'api/Parametre/TeslimatNoktasiListesi'
		);
	}
	postHizmetNoktaTelKayit(req: HizmetNoktasiIletisimRequest): Observable<void> {
		return this.http.post<void>(this.configService.config.apiUrl + 'api/Parametre/HizmetNoktasiTelefonKayit', req);
	}
	getTelefonSecenekler(): Observable<HizmetNoktasiTelefonSecenekModel[]> {
		return this.http.get<HizmetNoktasiTelefonSecenekModel[]>(
			this.configService.config.apiUrl + 'api/Parametre/CepTelefonSecenekleriGetir'
		);
	}
	postTelGetir(txtLokasyonKod: string): Observable<LokasyonTelResponse> {
		const params = new HttpParams().set('txtLokasyonKod', txtLokasyonKod.toString());
		return this.http.get<LokasyonTelResponse>(this.configService.config.apiUrl + 'api/Parametre/TelGetir', {
			params: params
		});
	}
}
