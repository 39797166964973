import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';

import { BehaviorSubject } from 'rxjs';
import { MiyaUser } from './auth/_services/auth.service';
import { notificationRequest } from './Notification/model/NotificationRequest';
import { NotificationService } from './Notification/NotificationService';
@Injectable()
export class MessagingService {
	currentMessage = new BehaviorSubject(null);
	constructor(private angularFireMessaging: AngularFireMessaging, private notificationService: NotificationService) {
		this.angularFireMessaging.onMessage;
		this.angularFireMessaging.onTokenRefresh;
		this.angularFireMessaging.messages.subscribe(message => {
			// Firebase'den yeni bir bildirim geldiğinde bu işlev çağrılır
			// message içinde bildirimle ilgili bilgilere erişebilirsiniz
			this.currentMessage.next(message);
		});
	}
	requestPermission() {
		this.angularFireMessaging.requestToken.subscribe(
			token => {
				if (token.length > 0 || token !== undefined) {
					const userStr = localStorage.getItem('User');
					const lngDistKod = localStorage.getItem('lngDistKod');
					let user = new MiyaUser();
					if (userStr) {
						user = JSON.parse(userStr) as MiyaUser;
					}
					const request: notificationRequest = {
						LngDistKod: +lngDistKod,
						LngKullaniciKod: +user.ExternalId,
						TxtNotificationToken: token,
						LngKod: 0
					};
					this.notificationService.NotificationKaydet(request).subscribe(f => {
						console.log(f);
					});
				}
			},
			err => {
				console.error('Unable to get permission to notify.', err);
			}
		);
		this.angularFireMessaging.onTokenRefresh;
	}
	receiveMessage() {
		this.angularFireMessaging.messages.subscribe(payload => {
			console.log('new message received. ', payload);
			this.currentMessage.next(payload);
			new window.Notification('Yeni Mesaj', { body: 'Yeni Filo Siparişi Geldi.' });
		});
	}
}
